import React, { useEffect, useState } from 'react'

function Cookie () {
  const [show, setShow] = useState(localStorage.getItem('show-cookie-bar') !== '0')

  useEffect(() => {
    if (!show) { localStorage.setItem('show-cookie-bar', '0') }
  }, [show])

  return (
    show &&
        (
            <div className="w-full h-full fixed bottom-0 left-0 z-20" style={{ backgroundColor: '#00000080' }}>
                <div className="dark:bg-true-dark py-10 absolute bottom-0 left-0 w-full" style={{ backgroundColor: 'wheat' }}>
                    <div className="container h-full flex flex-col md:flex-row items-center">
                        <svg className="md:mr-12 md:w-auto w-1/2" width="181" height="181" viewBox="0 0 181 181" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0)">
                                <path d="M159.069 65.0377C158.958 56.0286 154.165 47.7269 146.419 43.126C144.751 42.1351 143.207 40.9626 141.812 39.6366C134.943 46.0775 123.139 43.6928 119.743 34.2378C110.115 35.9774 102.308 27.4575 103.887 18.5418C103.639 18.4133 103.394 18.2813 103.15 18.1444C95.2919 13.7369 85.7063 13.7369 77.8484 18.1448C74.0792 20.2593 69.8397 21.3953 65.5179 21.4488C56.5088 21.5604 48.2074 26.3531 43.6062 34.0994C41.3989 37.8154 38.2956 40.9187 34.5797 43.1259C26.8334 47.7272 22.0405 56.0286 21.9289 65.0378C21.8754 69.3597 20.7394 73.5986 18.6249 77.3682C14.2171 85.2261 14.2171 94.8117 18.625 102.67C20.7395 106.439 21.8755 110.678 21.929 115C22.0406 124.009 26.8334 132.311 34.5796 136.912C38.2956 139.119 41.3989 142.222 43.6061 145.938C48.2074 153.685 56.5088 158.477 65.518 158.589C69.8399 158.643 74.0788 159.779 77.8484 161.893C85.7063 166.301 95.2919 166.301 103.15 161.893C106.919 159.778 111.159 158.642 115.48 158.589C124.489 158.477 132.791 153.685 137.392 145.938C139.599 142.222 142.703 139.119 146.419 136.912C154.165 132.311 158.958 124.009 159.069 115C159.123 110.678 160.259 106.439 162.373 102.67C166.781 94.8117 166.781 85.2261 162.373 77.3682C160.259 73.5987 159.123 69.3597 159.069 65.0377Z" fill="#EDB400" />
                                <path d="M89.2775 158.833C85.5079 156.718 81.2687 155.583 76.947 155.529C67.9379 155.417 59.6364 150.624 55.0352 142.878C52.828 139.162 49.7247 136.059 46.0087 133.852C38.2624 129.25 33.4696 120.949 33.3581 111.94C33.3047 107.618 32.1687 103.379 30.0541 99.6095C25.6462 91.7516 25.6461 82.1657 30.054 74.3081C32.1685 70.5385 33.3043 66.299 33.358 61.9777C33.4697 52.9688 38.2624 44.6671 46.0088 40.0658C49.7249 37.8586 52.8279 34.7553 55.0353 31.0393C57.4754 26.9307 60.9568 23.6535 65.0321 21.4644C56.2116 21.7395 48.1254 26.4945 43.6075 34.1014C41.4001 37.8174 38.2968 40.9207 34.581 43.1279C26.8346 47.7291 22.0419 56.0308 21.9302 65.0397C21.8766 69.3613 20.7407 73.6005 18.6262 77.3701C14.2183 85.2278 14.2183 94.8134 18.6263 102.671C20.7408 106.441 21.8767 110.68 21.9302 115.002C22.0418 124.011 26.8346 132.313 34.5808 136.913C38.2968 139.121 41.4002 142.224 43.6073 145.94C48.2086 153.686 56.51 158.479 65.5192 158.591C69.8411 158.644 74.0799 159.78 77.8496 161.895C85.5655 166.223 94.9466 166.298 102.722 162.126C98.0985 162.268 93.4448 161.171 89.2775 158.833Z" fill="#DAA706" />
                                <path d="M65.5482 99.6511C64.3646 99.9682 63.1486 99.2659 62.8315 98.0826L61.5858 93.4338C61.2688 92.2504 61.9707 91.0342 63.1543 90.717C64.338 90.3999 65.554 91.1022 65.8711 92.2855L67.1167 96.9343C67.434 98.1173 66.7316 99.334 65.5482 99.6511Z" fill="#191A1B" />
                                <path d="M116.923 85.8853C115.74 86.2024 114.524 85.5001 114.206 84.3168L112.961 79.668C112.644 78.4846 113.346 77.2684 114.529 76.9512C115.713 76.6341 116.929 77.3364 117.246 78.5197L118.492 83.1685C118.809 84.3516 118.107 85.5681 116.923 85.8853Z" fill="#191A1B" />
                                <path d="M92.1565 96.2039C88.5022 97.1831 84.6668 96.578 81.6337 94.5444C80.6162 93.862 80.3445 92.484 81.0269 91.4665C81.7093 90.449 83.087 90.1774 84.1047 90.8594C86.0741 92.1802 88.5902 92.5663 91.0079 91.9185C93.4254 91.2707 95.4117 89.6785 96.4571 87.5496C96.997 86.4499 98.3261 85.9966 99.4262 86.5368C100.526 87.0768 100.98 88.406 100.439 89.5058C98.8297 92.7834 95.8106 95.2248 92.1565 96.2039Z" fill="#191A1B" />
                                <path d="M62.8027 57.2198C68.1582 55.7848 71.6056 51.2844 70.5025 47.1678C69.3995 43.0512 64.1638 40.8774 58.8083 42.3124C53.4528 43.7474 50.0055 48.2478 51.1085 52.3644C52.2116 56.481 57.4472 58.6548 62.8027 57.2198Z" fill="#7C4B22" />
                                <path d="M61.707 131.778C67.0625 130.343 70.5099 125.843 69.4068 121.726C68.3038 117.61 63.0681 115.436 57.7126 116.871C52.3571 118.306 48.9098 122.806 50.0128 126.923C51.1159 131.039 56.3515 133.213 61.707 131.778Z" fill="#7C4B22" />
                                <path opacity="0.7" d="M132.191 125.759C137.547 124.324 140.994 119.823 139.891 115.707C138.788 111.59 133.552 109.416 128.197 110.851C122.841 112.286 119.394 116.787 120.497 120.903C121.6 125.02 126.836 127.194 132.191 125.759Z" fill="#7C4B22" />
                                <path d="M95.8314 141.084C99.5748 140.081 101.985 136.936 101.214 134.059C100.443 131.182 96.7833 129.663 93.0398 130.666C89.2964 131.669 86.8866 134.814 87.6575 137.691C88.4283 140.568 92.0879 142.087 95.8314 141.084Z" fill="#7C4B22" />
                                <path d="M102.781 55.4925C106.524 54.4894 108.934 51.3441 108.163 48.4673C107.392 45.5904 103.733 44.0714 99.9891 45.0745C96.2456 46.0775 93.8358 49.2228 94.6067 52.0997C95.3775 54.9765 99.0371 56.4956 102.781 55.4925Z" fill="#7C4B22" />
                                <path opacity="0.3" d="M40.9818 93.2226C44.7252 92.2195 47.135 89.0742 46.3641 86.1973C45.5933 83.3205 41.9337 81.8015 38.1902 82.8045C34.4468 83.8076 32.037 86.9529 32.8079 89.8297C33.5787 92.7066 37.2383 94.2256 40.9818 93.2226Z" fill="#7C4B22" />
                                <path opacity="0.3" d="M143.492 85.7352C147.235 84.7322 149.645 81.5869 148.874 78.71C148.103 75.8331 144.443 74.3141 140.7 75.3172C136.957 76.3202 134.547 79.4655 135.318 82.3424C136.088 85.2193 139.748 86.7383 143.492 85.7352Z" fill="#7C4B22" />
                            </g>
                            <defs>
                                <clipPath id="clip0">
                                    <rect width="147" height="147" fill="white" transform="translate(0.480469 38.0464) rotate(-15)" />
                                </clipPath>
                            </defs>
                        </svg>
                        <div className="max-w-2xl">
                            <div className="font-black text-heading-sm">Tanımlama Bilgilerinin Kullanımı</div>
                            <div className="text-sm my-4">
                                Ziyaretçilerimizin web sitemizi nasıl kullandıklarına dair bilgi alabilmek için üçüncü partilerinkiler
                                de dahil olmak üzere, Tanımlama Bilgileri (çerezler) kullanmaktayız. Bu bilgiler sizlere mümkün olan
                                en iyi deneyimi sunmamıza, web sitemizi geliştirebilmemize ve size ilgilenebileceğiniz teklifleri
                                gösterebilmemize yardımcı olmaktadır. “Kabul ediyorum” butonuna tıklayarak bu tanımlama bilgilerinin
                                kullanılmasını kabul etmiş sayılmaktasınız.
                            </div>
                            <button onClick={() => { setShow(false) }} className="border border-true-white rounded-xl px-8 py-3 dark:hover:border-true-primary dark:hover:bg-true-primary">
                                Kabul Et
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
  )
}

export default Cookie
