import React from 'react'

const WarningSvg = ({ theme, className }) => {
  const colors = {
    dark: '#046EFF',
    light: '#191A1B'
  }

  return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M22.9163 12.4997C22.9163 18.2538 18.2528 22.9163 12.4997 22.9163C6.74655 22.9163 2.08301 18.2538 2.08301 12.4997C2.08301 6.74759 6.74655 2.08301 12.4997 2.08301C18.2528 2.08301 22.9163 6.74759 22.9163 12.4997Z" fill={colors[theme]}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M13.4064 13.1572C13.4064 13.6593 12.997 14.0687 12.495 14.0687C11.9929 14.0687 11.5835 13.6593 11.5835 13.1572V8.55306C11.5835 8.05098 11.9929 7.6416 12.495 7.6416C12.997 7.6416 13.4064 8.05098 13.4064 8.55306V13.1572ZM11.5887 16.4622C11.5887 15.9601 11.996 15.5508 12.495 15.5508C13.0085 15.5508 13.4169 15.9601 13.4169 16.4622C13.4169 16.9643 13.0085 17.3737 12.5054 17.3737C12.0002 17.3737 11.5887 16.9643 11.5887 16.4622Z" fill="white"/>
        </svg>
  )
}

export default WarningSvg
