import React, { useEffect } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import ReactGA from 'react-ga'
import {
  BlackstarPage,
  CompanyPage,
  ContactPage,
  FAQPage,
  GenericPage,
  HomePage,
  LibraryPage,
  RoadmapPage,
  WhatIsTrueFeedBack,
  Tokenomics,
  DataAnalyze,
  StrategicPartners,
  AdService,
  ContentService,
  BlockchainTechnologies,
  Team,
  Media
} from './pages'
import ScrollTop from './components/ScrollTop'

import './App.css'
import 'react-toastify/dist/ReactToastify.min.css'
import SignIn from './pages/SignIn'
import Claim from './pages/Claim'

const TRACKING_ID = 'G-CKSXWNPPHS'

ReactGA.initialize(TRACKING_ID)
// window.Buffer = window.Buffer || require("buffer").Buffer;

function App () {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  return (
      <BrowserRouter>
        <ScrollTop />
        <ToastContainer theme="dark" position="bottom-center" />
        <Switch>
          <Route path="/claim">
            <Claim />
          </Route>
          <Route path="/signIn">
            <SignIn />
          </Route>
          <Route path="/media">
            <Media />
          </Route>
          <Route path="/what-is-truefeedback">
            <WhatIsTrueFeedBack />
          </Route>
          <Route path="/tokenomics">
            <Tokenomics />
          </Route>
          <Route path="/data-analyze">
            <DataAnalyze />
          </Route>
          <Route path="/strategic-partners">
            <StrategicPartners />
          </Route>
          <Route path="/services/ad">
            <AdService />
          </Route>
          <Route path="/services/content">
            <ContentService />
          </Route>
          <Route path="/services/blockchain-technologies">
            <BlockchainTechnologies />
          </Route>
          <Route path="/team">
            <Team />
          </Route>
          <Route path="/blackstar">
            <BlackstarPage />
          </Route>
          <Route path="/company">
            <CompanyPage />
          </Route>
          <Route path="/contact">
            <ContactPage />
          </Route>
          <Route path="/roadmap">
            <RoadmapPage />
          </Route>
          <Route path="/library/faq">
            <FAQPage />
          </Route>
          <Route path="/library/:page">
            <GenericPage />
          </Route>
          <Route path="/library">
            <LibraryPage />
          </Route>
          <Route path="/:page">
            <GenericPage />
          </Route>
          <Route path="/">
            <HomePage />
          </Route>
        </Switch>
      </BrowserRouter>
  )
}

export default App
