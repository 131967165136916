import React from 'react'

const LinkedinSvg = ({ theme, className }) => {
  const colors = {
    dark: '#B3B3B3',
    light: '#191A1B'
  }

  return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path fillRule="evenodd" clipRule="evenodd" d="M12.0625 10.5312C12.0625 11.3769 11.3769 12.0625 10.5312 12.0625C9.68556 12.0625 9 11.3769 9 10.5312C9 9.68556 9.68556 9 10.5312 9C11.3769 9 12.0625 9.68556 12.0625 10.5312ZM9 13.375H12.1308V23H9V13.375ZM20.6585 13.4879C20.6474 13.4844 20.6365 13.4808 20.6256 13.4772L20.6256 13.4772C20.6039 13.47 20.5821 13.4628 20.5587 13.4564C20.5167 13.4468 20.4747 13.4389 20.4319 13.4319C20.2656 13.3986 20.0836 13.375 19.8701 13.375C18.0449 13.375 16.8872 14.7024 16.5058 15.2151V13.375H13.375V23H16.5058V17.75C16.5058 17.75 18.8717 14.4548 19.8701 16.875V23H23V16.5049C23 15.0506 22.0034 13.8387 20.6585 13.4879Z" fill={colors[theme]}/>
        </svg>
  )
}

export default LinkedinSvg
