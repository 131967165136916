import React from 'react'

function OutlineButton ({
  arrow = true,
  children,
  href,
  className,
  onClick,
  spacing = true,
  download = false,
  fill = true,
  target = '_blank'
}) {
  const fillClasses = [
    'bg-true-secondary',
    'border-true-secondary',
    'dark:bg-true-primary',
    'dark:border-true-primary'
  ]

  const baseClasses = [
    className,
    'inline-flex',
    'justify-between',
    'transition-colors',
    'text-white',
    'border',
    'border-white',
    'rounded-xl',
    'hover:bg-true-secondary',
    'hover:border-true-secondary',
    'hover:text-true-dark',
    'dark:hover:bg-true-primary',
    'dark:hover:border-true-primary',
    'dark:hover:text-true-white',
    'text-true-dark',
    'dark:text-true-white',
    'p-4',
    'md:w-56',
    (spacing ? 'mt-6 mb-14 md:mt-12' : '')
  ]
  const classes = fill ? [...baseClasses, ...fillClasses] : [...baseClasses]
  return (
        <a href={href} download={download} className={classes.join(' ')} target={target} rel="noreferrer" onClick={() => {
          onClick && onClick()
        }}>
            {children}
            {
                arrow &&
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    {
                        download
                          ? (
                            <path
                                d="M9.16683 3.33317L9.16683 13.4748L4.5085 8.8165L3.3335 9.99984L10.0002 16.6665L16.6668 9.99984L15.4918 8.82484L10.8335 13.4748L10.8335 3.33317L9.16683 3.33317Z"
                                fill="currentColor"/>
                            )
                          : (
                            <path
                                d="M3.33268 10.8333L13.4743 10.8333L8.81602 15.4916L9.99935 16.6666L16.666 9.99996L9.99935 3.33329L8.82435 4.50829L13.4743 9.16663L3.33268 9.16663L3.33268 10.8333Z"
                                fill="currentColor"/>
                            )
                    }
                </svg>
            }
        </a>
  )
}

export default OutlineButton
