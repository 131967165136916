/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { withTranslation, Trans } from 'react-i18next'
import IconAds from '../assets/icon-ads.svg'
import IconAnalize from '../assets/icon-analize.svg'
import IconEvents from '../assets/icon-events.svg'
import IconGames from '../assets/icon-games.svg'
import IconNews from '../assets/icon-news.svg'
import IconShopping from '../assets/icon-shopping.svg'
import IconTasks from '../assets/icon-tasks.svg'
import OutlineButton from '../components/OutlineButton'
import AppLayout from '../layouts/AppLayout'
import Slider1 from '../assets/slider_01.png'
import Slider2 from '../assets/slider_02.png'
import Slider3 from '../assets/slider_03.mp4'
import Slider3_en from '../assets/slider_03_en.mp4'
import Slider3_failback from '../assets/slider_03.png'
import Slider3_en_failback from '../assets/slider_03_en.png'
import HomeFeaturesSvg from '../assets/HomeFeatures.svg'
import BadgeAndroidSvg from '../assets/BadgeAndroid.svg'
// import BadgeiOSSvg from '../assets/BadgeiOS.svg'
import HomeManWomanSvg from '../assets/HomeManWoman.svg'
import HomeLogoWaveSvg from '../assets/HomeLogoWave.svg'
import CeloLogo from '../assets/celo-logo.png'
import BSCLogo from '../assets/bsc-logo.png'
import KAVALogo from '../assets/kavalogo.png'
import EthereumLogo from '../assets/ethereum-logo.png'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import CountUp from 'react-countup'
import { Waypoint } from 'react-waypoint'
import { ContractAddress } from '../components/ContractAddress'
import HomeEcosystem from '../components/HomeEcosystem'
import Map from '../components/Map'
import Navbar from '../components/Navbar'

function Home ({ i18n, t }) {
  const headers = [
    {
      name: t('coming_soon'),
      active: true,
      svgClass: 'md:-mr-24 max-w-md',
      title1: '',
      title1trans: true,
      title2: t('home_1_c_3_line_1'),
      title2class: 'text-true-secondary',
      paragraph: t('home_1_c_3_paragraph'),
      video: true
    },
    {
      name: t('TrueFeedBack'),
      active: false,
      svg: Slider1,
      svgClass: 'md:-mr-24 max-w-md',
      title: t('home_1_c_1_line_2'),
      title1trans: true,
      title2: t('home_1_c_1_line_2'),
      title2class: 'text-true-secondary',
      paragraph: t('home_1_c_1_paragraph')
    },
    {
      name: t('Celo'),
      active: false,
      svg: Slider2,
      svgClass: 'md:-mr-24 max-w-md',
      title: t('home_1_c_2_line_1'),
      title1trans: true,
      title2: t('home_1_c_2_line_1'),
      title2class: 'text-true-secondary',
      paragraph: t('home_1_c_2_paragraph')
    }
  ]

  const blakcstarWithNumbers = [
    { number: 151782, text: t('home_blackstar_with_numbers_1') },
    { number: 107, text: t('home_blackstar_with_numbers_2') },
    { number: 54702852, text: t('home_blackstar_with_numbers_3') },
    { number: 14503053, text: t('home_blackstar_with_numbers_4') },
    { number: 245712, text: t('home_blackstar_with_numbers_5') },
    { number: 17648553, text: t('home_blackstar_with_numbers_6') },
    { number: 11334371, text: t('home_blackstar_with_numbers_7') },
    { number: 608265, text: t('home_blackstar_with_numbers_8') }
  ]

  const [features, setFeatures] = useState([
    {
      name: t('home_features_ad_name'),
      icon: IconAds,
      description: t('home_features_ad_description'),
      link: '/',
      active: true
    },
    {
      name: t('home_features_game_name'),
      icon: IconGames,
      description: t('home_features_game_description'),
      link: '/',
      active: false
    },
    {
      name: t('home_features_tasks_name'),
      icon: IconTasks,
      description: t('home_features_tasks_description'),
      link: '/',
      active: false
    },
    {
      name: t('home_features_news_name'),
      icon: IconNews,
      description: t('home_features_news_description'),
      link: '/',
      active: false
    },
    {
      name: t('home_features_event_name'),
      icon: IconEvents,
      description: t('home_features_event_description'),
      link: '/',
      active: false
    },
    {
      name: t('home_features_survey_name'),
      icon: IconAnalize,
      description: t('home_features_survey_description'),
      link: '/',
      active: false
    },
    {
      name: t('home_features_shopping_name'),
      icon: IconShopping,
      description: t('home_features_shopping_description'),
      link: '/',
      active: false
    }
  ])
  const [counterVisible, setCounterVisible] = useState(false)
  const [theme, setTheme] = useState(localStorage.getItem('theme'))

  return (
    <AppLayout>
      <Navbar setTheme={setTheme} />
      <div
        className='w-full bg-section bg-true-stat dark:bg-true-dark'
        style={{ boxShadow: '0 2px 4px 0px #333' }}
      >
        <Slider
          {...{
            infinite: true,
            dots: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true
          }}
          className='font-bold'
        >
          {headers.map(header => {
            return (
              <div key={`home-header-${header.name}`} className='slide-item'>
                <div className='slide-wrapper is-mobile'>
                  <div className='slide-inner'>
                    <div className='home-content'>{header.title}</div>
                    <a href='#!' className='slide-item-bg'>
                      {header?.video && (
                        <video autoPlay loop muted>
                          <source
                            src={i18n.language === 'tr' ? Slider3 : Slider3_en}
                            alt={header.title}
                          />
                          <img
                            scr={
                              i18n.language === 'tr'
                                ? Slider3_failback
                                : Slider3_en_failback
                            }
                            alt='tfb'
                          />
                        </video>
                      )}
                      {!header?.video && (
                        <img src={header.svg} alt={header.title} />
                      )}
                    </a>
                  </div>
                </div>
                <div className='slide-wrapper is-desktop'>
                  <div className='home-content'>{header.title}</div>
                  <div className='slide-item-bg'>
                    {header?.video && (
                      <video autoPlay loop muted>
                        <source
                          src={i18n.language === 'tr' ? Slider3 : Slider3_en}
                          alt={header.title}
                        />
                        <img
                          scr={
                            i18n.language === 'tr'
                              ? Slider3_failback
                              : Slider3_en_failback
                          }
                          alt='tfb'
                        />
                      </video>
                    )}
                    {!header?.video && (
                      <img src={header.svg} alt={header.title} />
                    )}
                  </div>
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
      <div className='w-full'>
        <div className='container pt-6 md:mt-10 md:pb-10 flex flex-col md:flex-row md:justify-between md:items-center'>
          <div className='flex flex-col text-center md:text-left md:max-w-sm'>
            <h3 className='font-bold text-3xl md:text-5xl tracking-tighter gradient-title'>
              {t('home_2_title')}
            </h3>
            <h5 className='md:text-2xl mt-2'>{t('home_2_paragraph_1')}</h5>
            <div className='text-sm md:text-base mt-4'>
              {t('home_2_paragraph_2')}
            </div>

            <OutlineButton
              href='/what-is-truefeedback'
              className='hidden md:inline-flex'
            >
              {t('Detail')}
            </OutlineButton>
          </div>
          <HomeEcosystem
            language={i18n.language}
            theme={theme}
            className='w-auto md:w-1/2 lg:w-auto'
            alt='ecosystem-svg'
          />
          <OutlineButton href='/what-is-truefeedback' className='md:hidden'>
            {t('Detail')}
          </OutlineButton>
        </div>
      </div>
      <div className='w-full pt-6 section-gradient-1 rounded-tr-medium md:rounded-tr-large md:pb-24'>
        <div className='container pt-6 md:mt-20 flex flex-col md:flex-row md:justify-between md:items-center'>
          <img
            src={HomeFeaturesSvg}
            alt='Home features img'
            className='hidden md:block'
          />
          <div className='flex flex-col text-center md:text-left md:max-w-sm'>
            <h3 className='font-bold text-3xl md:text-5xl tracking-tighter text-true-secondary'>
              {t('home_3_title')}
            </h3>
            <h5 className='text-base md:text-2xl mt-2'>
              {t('home_3_paragraph_1')}
            </h5>
            <div className='text-sm md:text-base mt-4'>
              {t('home_3_paragraph_2')}
            </div>
            <div className='mt-4 w-full md:hidden flex justify-center'>
              <img
                src={HomeFeaturesSvg}
                alt='Home features img'
                className='w-2/3'
              />
            </div>
            <div className='flex flex-col lg:flex-row flex-nowrap items-center justify-between max-h-44'>
              {/* <a
                href='https://apps.apple.com/app/tfb-blackstar/id1540246904'
                target='_blank'
                rel='noreferrer'
              >
                <img
                  src={BadgeiOSSvg}
                  className='cursor-pointer lg:max-h-14 mt-4'
                  alt='ios-svg'
                />
              </a> */}
              <a
                href='https://play.google.com/store/apps/details?id=com.trueefeedbackchain.bountypoll'
                target='_blank'
                rel='noreferrer'
              >
                <img
                  src={BadgeAndroidSvg}
                  className='cursor-pointer lg:max-h-14 mt-4'
                  alt='android-svg'
                />
              </a>
            </div>
            <OutlineButton href='/blackstar'>{t('Detail')}</OutlineButton>
          </div>
        </div>
      </div>
      <div className='w-full md:rounded-br-large pt-px md:pt-10 pb-12'>
        <div className='container md:pt-24'>
          <div className='hidden lg:flex justify-between w-full text-center'>
            {features.map((feature, i) => {
              let className =
                'feature-button w-32 cursor-pointer p-8 text-sm whitespace-nowrap'
              if (feature.active) className += ' home-feature__active'
              return (
                <div
                  key={`home-feature-${feature.name}`}
                  className={className}
                  onClick={() => {
                    setFeatures(
                      features.map((feature, index) => {
                        feature.active = index === i
                        return feature
                      })
                    )
                  }}
                >
                  <div className='w-full inline-flex flex-col justify-center items-center'>
                    <img
                      className='relative h-12 mb-4 transition-all duration-150'
                      src={feature.icon}
                      alt='feature-icon-svg'
                    />
                    <span>{feature.name}</span>
                  </div>
                </div>
              )
            })}
          </div>
          <div className='w-full flex mt-16'>
            {features
              .filter(feature => feature.active)
              .map(feature => {
                return (
                  <React.Fragment key={`home-active-feature-${feature.name}`}>
                    <img
                      src={feature.icon}
                      style={{
                        height: '10rem',
                        width: '10rem',
                        transform: 'rotate(-17.6deg)'
                      }}
                      className='hidden md:block'
                      alt='feature-icon-svg'
                    />
                    <div className='md:ml-16 max-w-xl  text-center md:text-left'>
                      <div className='font-bold text-3xl text-true-lightgray dark:text-true-white'>
                        {feature.name}
                      </div>
                      <div className='mt-8 text-true-dark dark:text-true-white'>
                        {feature.description}
                      </div>
                    </div>
                  </React.Fragment>
                )
              })}
          </div>
          <Slider
            {...{
              infinite: false,
              speed: 500,
              slidesToShow: 2,
              slidesToScroll: 2,
              dots: true,
              arrows: false
            }}
            className='mt-5 pb-5 md:hidden'
          >
            {features.map((feature, i) => {
              let className =
                'w-32 cursor-pointer p-8 text-sm whitespace-nowrap'
              if (feature.active) className += ' home-feature__active'
              return (
                <div
                  key={`home-slider-feature-${feature.name}`}
                  className={className}
                  onClick={() => {
                    setFeatures(
                      features.map((feature, index) => {
                        feature.active = index === i
                        return feature
                      })
                    )
                  }}
                >
                  <div className='w-full inline-flex flex-col justify-center items-center'>
                    <img
                      className='relative h-12 mb-4 transition-all duration-150'
                      src={feature.icon}
                      alt='feature-icon-svg'
                    />
                    <span>{feature.name}</span>
                  </div>
                </div>
              )
            })}
          </Slider>
        </div>
      </div>
      <Waypoint onEnter={() => setCounterVisible(true)} />
      <div className='w-full section-gradient-2 rounded-br-medium'>
        <div className='container flex flex-col py-12 md:py-20'>
          <h3 className='gradient-title text-3xl md:text-5xl font-bold text-center md:text-left'>
            {t('home_numbers_title')}
          </h3>

          <div className='mt-12 grid grid-cols-2 justify-between md:grid-cols-4 gap-7'>
            {blakcstarWithNumbers.map(item => {
              return (
                <div
                  key={`home-statistics-${item.text}`}
                  className='text-true-dark dark:text-true-white w-full md:mb-0 mb-4 py-7 rounded-3xl flex justify-evenly px-2 items-center text-center gap-2 flex-col bg-input-bg'
                >
                  <strong className='font-bold text-3xl md:text-3xl'>
                    {counterVisible
                      ? (
                      <CountUp end={item.number} separator='.' duration={1} />
                        )
                      : (
                          0
                        )}
                  </strong>
                  <span className='text-sm text-true-lightgray dark:text-true-muted'>
                    {item.text}
                  </span>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <div className='w-full md:dark:bg-true-darkergray md:bg-true-section-light rounded-tr-medium rounded-bl-medium md:rounded-bl-large mt-5 mb-5'>
        <div className='container relative py-12 md:pb-24 md:pt-12'>
          <div className='flex flex-col dark:text-true-white text-true-dark md:max-w-screen-md'>
            <h3 className='text-3xl md:text-5xl md:leading-normal text-center md:text-left gradient-title'>
              <Trans
                i18nKey='home_ourpartners_title'
                components={{ span: <strong className='font-bold' /> }}
              />
            </h3>

            <Slider
              {...{
                infinite: false,
                speed: 500,
                slidesToShow: 3,
                slidesToScroll: 2,
                dots: true,
                arrows: false
              }}
              className='mt-5 pb-5 md:hidden'
            >
              <a className='flex' href='https://oceanland.io'>
                <img
                  className='ml-auto mr-auto'
                  src='/partners/oceanland_logo.png'
                  alt=''
                />
              </a>
              <a className='flex' href='https://celo.org'>
                <img
                  className='ml-auto mr-auto'
                  src='/partners/celo_logo.png'
                  alt=''
                />
              </a>
              <a className='flex' href='https://guildturk.io'>
                <img
                  className='ml-auto mr-auto'
                  src='/partners/guildturk.png'
                  alt=''
                />
              </a>
              <a className='flex' href='https://tegisto.io'>
                <img
                  className='ml-auto mr-auto'
                  src='/partners/tegisto_logo.png'
                  alt=''
                />
              </a>
            </Slider>
            <div className='hidden md:flex md:flex-row gap-10 items-start mt-18 max-w-screen-sm h-32'>
              <a
                className='inline-flex h-full flex-col justify-between items-center'
                href='https://oceanland.io/'
              >
                <img src='/partners/oceanland_logo.png' alt='' />
                <span className='font-bold'>Oceanland</span>
              </a>
              <a
                className='inline-flex h-full flex-col justify-between items-center'
                href='https://celo.org/'
              >
                <img src='/partners/celo_logo.png' alt='' />
                <span className='font-bold'>Celo</span>
              </a>
              <a
                className='inline-flex h-full flex-col justify-between items-center'
                href='https://guildturk.io'
              >
                <img src='/partners/guildturk.png' alt='' />
                <span className='font-bold'>Guildtürk</span>
              </a>
              <a
                className='inline-flex h-full flex-col justify-between items-center'
                href='https://tegisto.io'
              >
                <img src='/partners/tegisto_logo.png' alt='' />
                <span className='font-bold'>Tegisto</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full md:dark:bg-true-darkergray md:bg-true-section-light rounded-tl-medium md:rounded-tl-large'>
        <div className='container relative py-12 md:pb-24 md:pt-12'>
          <div className='flex flex-col dark:text-true-white text-true-dark md:max-w-screen-md'>
            <h3 className='text-3xl md:text-5xl md:leading-normal text-center md:text-left gradient-title'>
              <Trans
                i18nKey='home_stock_market_title'
                components={{ span: <strong className='font-bold' /> }}
              />
            </h3>

            <Slider
              {...{
                infinite: false,
                speed: 500,
                slidesToShow: 3,
                slidesToScroll: 2,
                dots: true,
                arrows: false
              }}
              className='mt-5 pb-5 md:hidden'
            >
              <a
                className='flex'
                href='https://www.mexc.com/exchange/TFBX_USDT'
              >
                <img
                  className='ml-auto mr-auto'
                  src='/icons/icon-mexc.png'
                  alt=''
                />
              </a>
              <a
                className='flex'
                href='https://info.ubeswap.org/pair/0x6328A72067865da05D4B76f1380420E564edaa7d'
              >
                <img
                  className='ml-auto mr-auto'
                  src='/icons/icon-ube.png'
                  alt=''
                />
              </a>
            </Slider>
            <div className='hidden md:flex md:flex-row gap-10 items-start mt-18 max-w-screen-sm h-32'>
              <a
                className='inline-flex h-full flex-col justify-between items-center'
                href='https://www.mexc.com/exchange/TFBX_USDT'
              >
                <img src='/icons/icon-mexc.png' alt='' />
                <span className='font-bold'>MEXC</span>
              </a>

              <a
                className='inline-flex h-full flex-col justify-between items-center'
                href='https://info.ubeswap.org/pair/0x6328A72067865da05D4B76f1380420E564edaa7d'
              >
                <img src='/icons/icon-ube.png' alt='' />
                <span className='font-bold'>Ubeswap</span>
              </a>
            </div>
            <div className='md:w-max mb-16'>
              <ContractAddress
                title={`Ethereum ${t('CONTRACT_ADDRESS')}`}
                address='0x4057db5bd9f67a566aa10e5587b1a964affc6a16'
                alt='Ethereum Logo'
                src={EthereumLogo}
              />
              <ContractAddress
                title={`Celo ${t('CONTRACT_ADDRESS')}`}
                address='0xbDd31EFfb9E9f7509fEaAc5B4091b31645A47e4b'
                showConvert
                alt='Celo Logo'
                src={CeloLogo}
              />
              <ContractAddress
                title={`Binance Smart Chain ${t('CONTRACT_ADDRESS')}`}
                address='0x1354E1C028Ad93eD9992eE3bFd3ee5608489439C'
                alt='BSC Logo'
                src={BSCLogo}
              />
              <ContractAddress
                title={`Kava Chain ${t('CONTRACT_ADDRESS')}`}
                address='0xbd10F04B8b5027761fCAAd42421aD5d0787211Ee'
                alt='Kava Logo'
                src={KAVALogo}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='w-full hidden md:block'>
        <div className='container relative'>
          <div className='absolute right-0'>
            <img
              src={HomeLogoWaveSvg}
              className='md:-mr-24'
              style={{ transform: 'translateY(-50%)' }}
              alt='logo-wave-svg'
            />
          </div>
        </div>
      </div>
      <div className='w-full'>
        <div className='container'>
          <h3 className='gradient-title text-3xl md:text-5xl font-bold text-center md:text-left mt-2 md:mt-10'>
            {t('home_offices_title')}
          </h3>
          <Map></Map>
        </div>
      </div>
    </AppLayout>
  )
}

export default withTranslation()(Home)
