import React from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import BackButton from '../components/BackButton'
import Header from '../containers/Header'
import AppLayout from '../layouts/AppLayout'

function Generic ({ match }) {
  const params = useParams()

  const { t } = useTranslation(['page'])

  return (
        <AppLayout>
            <Header>
                <div className="container">
                    <div className="flex flex-col-reverse md:flex-row justify-between py-8 md:py-12">
                        <div className="flex flex-col">
                            <BackButton />

                            <h3 className="my-4 text-heading-sm font-bold md:text-heading-lg dark:text-true-secondary text-true-primary">{t(params.page)}</h3>

                            <p className="mb-9 text-heading-sm md:text-heading-lg">
                                Bu sayfa bulunamadı !
                            </p>
                        </div>

                    </div>
                </div>
            </Header>
        </AppLayout>
  )
}

export default withTranslation()(Generic)
