import React from 'react'
import HomeEcosystemTrDarkSvg from '../assets/HomeEcosystem_TR_Dark.svg'
import HomeEcosystemTrLightSvg from '../assets/HomeEcosystem_TR_Light.svg'
import HomeEcosystemEnDarkSvg from '../assets/HomeEcosystem_EN_Dark.svg'
import HomeEcosystemEnLightSvg from '../assets/HomeEcosystem_EN_Light.svg'

export const HomeEcosystem = ({ language = 'en', theme = 'light', className = '', alt = '' }) => {
  return (
    <img
      className={className}
      src={language === 'tr'
        ? (theme === 'dark' ? HomeEcosystemTrDarkSvg : HomeEcosystemTrLightSvg)
        : (theme === 'dark' ? HomeEcosystemEnDarkSvg : HomeEcosystemEnLightSvg)}
      alt={alt}
    />
  )
}

export default HomeEcosystem
