import { useEffect, useRef } from 'react'

function useClickOutside (callback) {
  const callbackRef = useRef()
  const innerRef = useRef()

  // update callback on each render, so second useEffect has most recent callback
  useEffect(() => { callbackRef.current = callback })
  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => document.removeEventListener('click', handleClick)
    function handleClick (e) {
      if (innerRef.current && callbackRef.current &&
        !innerRef.current.contains(e.target)
      ) callbackRef.current(e)
    }
  }, []) // no dependencies -> stable click listener

  return innerRef // convenience for client (doesn't need to init ref himself)
}

export default useClickOutside
