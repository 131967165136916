/* eslint-disable camelcase */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import FacebookLogo from '../assets/social/facebook.svg'
import InstagramLogo from '../assets/social/Instagram.svg'
import LinkedinLogo from '../assets/social/linkedin.svg'
import TwitterLogo from '../assets/social/twitter.svg'
import YoutubeLogo from '../assets/social/youtube.svg'
import MediumLogo from '../assets/medium.png'
import LogoMain from '../assets/logo_main.svg'
import BadgeAndroid from '../assets/BadgeAndroid.svg'
// import BadgeiOS from '../assets/BadgeiOS.svg'
import LoadingSpinner from '../assets/loading-spinner.svg'
import { YMInitializer } from 'react-yandex-metrika'

const validateEmail = (email) => email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)

function Footer () {
  const { t } = useTranslation()

  const siteMap = [
    {
      title: t('about_us?'),
      links: [
        {
          label: t('what_is_truefeedback'),
          to: '/what-is-truefeedback'
        },
        {
          label: t('tokenomics'),
          to: '/tokenomics'
        },
        {
          label: t('team'),
          to: '/team'
        },
        {
          label: t('roadmap'),
          to: '/roadmap'
        }
      ]
    },
    {
      title: t('services'),
      links: [
        {
          label: t('ads_service'),
          to: '/services/ad'
        },
        {
          label: t('content_service'),
          to: '/services/content'
        },
        {
          label: t('blockchain_technogies'),
          to: '/services/blockchain-technologies'
        }
      ]
    },
    {
      title: t('TrueFeedBack'),
      links: [
        {
          label: t('AUDIT_REPORT'),
          to: 'https://www.certik.com/projects/truefeedback'
        },
        {
          label: t('documents'),
          to: '/library'
        },
        {
          label: t('blog'),
          to: 'https://truefeedbackio.medium.com/'
        },
        {
          label: t('white_paper'),
          to: '/library/whitepaper_en.pdf'
        },
        {
          label: t('faq'),
          to: '/library/faq'
        }
      ]
    },
    {
      title: t('contact_us'),
      links: [
        {
          label: t('contact'),
          to: '/contact'
        },
        {
          label: t('legal_notice'),
          to: '/'
        },
        {
          label: t('privacy_policy'),
          to: '/'
        }
      ]
    }
  ]

  const socialMediaLinks = [
    { logo: FacebookLogo, link: 'https://www.facebook.com/Truefeedbackio' },
    { logo: LinkedinLogo, link: 'https://www.linkedin.com/company/truefeedback' },
    { logo: TwitterLogo, link: 'https://twitter.com/Truefeedback_io' },
    { logo: YoutubeLogo, link: 'https://www.youtube.com/channel/UCIazc_Sn8P-1bsWWzQyZIMA' },
    { logo: InstagramLogo, link: 'https://www.instagram.com/truefeedback_io/' },
    { logo: MediumLogo, link: 'https://truefeedbackblackstar.medium.com' }
  ]

  const [mail_address, setMail] = useState('')
  const [loading, setLoading] = useState(false)

  const handleMail = (value) => {
    setMail(value.target.value)
  }

  const mail_subscribe = () => {
    if (validateEmail(mail_address)) {
      setLoading(true)
      fetch('https://api.truefeedback.io/web/subscription/email/' + mail_address)
        .then(({ status }) => {
          setLoading(false)
          if (status === 200) {
            setMail('')
            toast(t('EMAIL_SUBSCRIPTION_SUCCESSFUL'), { type: 'success' })
          } else if (status === 500) {
            toast(t('EMAIL_SUBSCRIPTION_SERVER_ERROR'), { type: 'error' })
          }
        })
        .catch(() => {
          setLoading(false)
          toast(t('EMAIL_SUBSCRIPTION_SERVER_ERROR'), { type: 'error' })
        })
    } else {
      toast(t('EMAIL_SUBSCRIPTION_VALIDATION_ERROR'), { type: 'error' })
    }
  }

  return (
        <div className="w-full footer-bg rounded-tl-medium md:rounded-tl-large text-center pt-8 md:text-left md:pt-10 mt-16">
            <div className="container">
                <div className="md:flex items-center">
                    <div className="md:text-3xl">
                        <div>{t('mail_list_header')}</div>
                    </div>
                    <div
                        className="md:ml-auto md:bg-true-white dark:bg-true-dark rounded-lg md:flex flex-1 mt-9 md:mt-0 justify-center">
                        <input
                            value={mail_address}
                            onChange={handleMail}
                            placeholder={t('your_email_address')}
                            type="email"
                            className="flex-1  md:text-left ring-0 dark:bg-true-dark rounded-lg outline-none md:text-lg p-4 md:p-2 md:mb-0 min-w-6"
                        />
                        <button
                            className="bg-true-secondary dark:text-true-darkgray md:px-6 rounded-lg h-14 px-2"
                            onClick={mail_subscribe}
                            disabled={loading}
                        >
                            {loading && (
                                <img
                                    src={LoadingSpinner}
                                    alt="Email subscription loading spinner"
                                    className="animate-spin w-6"
                                />
                            )}
                            {!loading && t('mail_subscribe')}
                        </button>
                    </div>
                </div>
                <div className="pt-5 pb-5">
                    <div className="text-true-secondary float-right">
                        <div className="w-full md:w-auto flex justify-between mt-3 md:justify-center">
                            {socialMediaLinks.map((socialMediaLink, index) => {
                              return (
                                    <a target="_blank" rel="noreferrer" href={socialMediaLink.link} key={index} className="ml-4">
                                        <img src={socialMediaLink.logo} alt="social-media-logo" height={22} width={22} />
                                    </a>
                              )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="hidden md:block border-b border-sidebar-seperator mt-10"/>
            <div className="container mt-8 md:mt-10">
                <div className="flex justify-between">
                    {siteMap.map((section, index) => {
                      return (
                            <div className="hidden md:block" key={'sitemap-' + index}>
                                <div className="text-sm font-semibold">{section.title}</div>
                                <div className="mt-3">
                                    {section.links.map((link) => {
                                      const isExternalLinkOrPDF = link.to.includes('http') ||
                                            link.to.includes('.pdf')
                                      if (isExternalLinkOrPDF) {
                                        return (
                                                <a
                                                    key={'sitemap-link' + link.label}
                                                    className="block text-xs mb-2 hover:text-true-primary"
                                                    href={link.to}
                                                >{link.label}</a>
                                        )
                                      }

                                      return (
                                            <Link
                                                key={'sitemap-link' + link.label}
                                                className="block text-xs mb-2 hover:text-true-primary"
                                                to={link.to}
                                            >{link.label}</Link>
                                      )
                                    })}
                                </div>
                            </div>
                      )
                    })}
                </div>
            </div>
            <div className="hidden md:block border-b border-sidebar-seperator mt-10"/>
            <div className="w-full">
                <div className="container flex mt-10 pb-12">
                    <img className="hidden md:block" src={LogoMain} alt="footer-logo" style={{ width: '20%' }} />
                    {/* <a
                        href="https://apps.apple.com/app/tfb-blackstar/id1540246904"
                        className="ml-auto"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={BadgeiOS} alt="badge-ios" style={{ width: '80%' }} />
                    </a> */}
                    <a
                        href="https://play.google.com/store/apps/details?id=com.trueefeedbackchain.bountypoll"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={BadgeAndroid} alt="badge-android" style={{ width: '80%' }} />
                    </a>
                    <YMInitializer accounts={[86637146]} options={{ clickmap: true, trackLinks: true, webvisor: true }} version="2" />
                </div>
            </div>
        </div>
  )
}

export default Footer
