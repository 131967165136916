import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import navbarItems from '../data/navbar.json'
import MenuItem from './MenuItem'
import MobileNavbar from './MobileNavbar'
import { useTranslation } from 'react-i18next'
import LogoMain from '../assets/logo_main.svg'
import ConversionIcon from '@rsuite/icons/Conversion'
import SignInIcon from '@rsuite/icons/Exit'
import {
  Whisper,
  Tooltip

} from 'rsuite'
import { ImStack } from 'react-icons/im'

function Navbar ({ setTheme, ...rest }) {
  const languageMap = {
    en: 'TR',
    tr: 'EN'
  }
  const history = useHistory()
  const { i18n, t } = useTranslation()
  const [menuShow, setMenuShow] = useState(false)
  const items = navbarItems
  const [selectedLanguage, setSelectedLanguage] = useState(languageMap[i18n.language])
  const changeLanguage = () => {
    if (i18n.language === 'en') {
      i18n.changeLanguage('tr').then(() => setSelectedLanguage('EN'))
      return
    }
    i18n.changeLanguage('en').then(() => setSelectedLanguage('TR'))
  }
  useEffect(() => {
    if (localStorage.getItem('theme') === 'dark' &&
      !document.body.classList.contains('dark')) {
      document.body.classList.add('dark')
    } else if (localStorage.getItem('theme') == null) {
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches
      if (prefersDark && !document.body.classList.contains('dark')) { document.body.classList.add('dark') }
    }
  }, [])

  const toggleTheme = () => {
    if (document.body.classList.contains('dark')) {
      document.body.classList.remove('dark')
      localStorage.setItem('theme', 'light')
      setTheme && setTheme('light')
    } else {
      document.body.classList.add('dark')
      localStorage.setItem('theme', 'dark')
      setTheme && setTheme('dark')
    }
  }

  return (
    <div className="bg-true-stat dark:bg-true-dark">
      <MobileNavbar setMenuShow={setMenuShow} show={menuShow} />
      <div className="container py-6">
        <div className="flex justify-between items-center">
          <Link to="/" className="max-w-brand-sm md:max-w-brand-lg">
            <img className="w-full h-11" src={LogoMain} alt="TrueFeedBack" />
          </Link>

          <div className="md:hidden">
            <button className="bg-true-secondary rounded-full inline-flex items-center justify-center p-2 z-20" onClick={() => (setMenuShow(true))}>
              <svg width="16" height="16" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="10.229" height="2.27936" rx="1.13968" fill="#282A2B" />
                <rect y="4.7486" width="15.1" height="2.27936" rx="1.13968" fill="#282A2B" />
                <rect y="9.39062" width="15.1" height="2.27936" rx="1.13968" fill="#282A2B" />
              </svg>
            </button>
          </div>

          <div className="hidden md:flex gap-2">
            {
              items.map((item, i) => {
                return (
                  <MenuItem title={item.title} to={item.href} dropdown={item?.items} key={'menu-item-' + i} />
                )
              })
            }
          </div>

          <div className="hidden md:flex items-center">
            <Whisper
              trigger={'hover'}
              placement='left'
              speaker={
                <Tooltip>{t('Staking Program')}</Tooltip>
              }
            >
              <button className="bg-true-secondary mr-2 h-8 w-8 inline-flex justify-center items-center p-2 rounded-full" onClick={() => (window.location.href = 'https://stake.truefeedback.io')}>
                <ImStack color='#000' />
              </button>
            </Whisper>
            <Whisper
              trigger={'hover'}
              placement='left'
              speaker={
                <Tooltip>{t('Sign-in to send claim request')}</Tooltip>
              }
            >
              <button className="bg-true-secondary mr-2 h-8 w-8 inline-flex justify-center items-center p-2 rounded-full" onClick={() => history.push('/signin')}>
                <SignInIcon color='#000' />
              </button>
            </Whisper>
            <button
              onClick={() => changeLanguage()}
              className="bg-true-darkgray mr-2 h-8 w-8 inline-flex justify-center items-center p-2 rounded-full text-xs">
              {selectedLanguage}
            </button>
            <button className="bg-true-darkgray mr-2 h-8 w-8 inline-flex justify-center items-center p-2 rounded-full" onClick={toggleTheme}>
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.00076 4.49133C6.51469 4.49133 4.49219 6.51423 4.49219 9.0003C4.49219 11.4864 6.51469 13.5093 9.00076 13.5093C11.4864 13.5093 13.5093 11.4868 13.5093 9.0003C13.5093 6.51383 11.4864 4.49133 9.00076 4.49133Z" fill="currentColor" />
                <path d="M9.0008 3.16606C8.51491 3.16606 8.12109 2.77224 8.12109 2.28674V0.879284C8.12109 0.39339 8.51491 -0.000427246 9.0008 -0.000427246C9.4867 -0.000427246 9.88052 0.39339 9.88052 0.879284V2.28674C9.88052 2.77224 9.4863 3.16606 9.0008 3.16606Z" fill="currentColor" />
                <path d="M9.0008 14.8336C8.51491 14.8336 8.12109 15.2274 8.12109 15.7133V17.1203C8.12109 17.6066 8.51491 18.0004 9.0008 18.0004C9.4867 18.0004 9.88052 17.6066 9.88052 17.1203V15.7133C9.88052 15.2274 9.4863 14.8336 9.0008 14.8336Z" fill="currentColor" />
                <path d="M13.1246 4.87485C12.7814 4.53125 12.7814 3.97441 13.1246 3.63081L14.1199 2.63551C14.4631 2.29231 15.0203 2.29231 15.3639 2.63551C15.7075 2.9791 15.7075 3.53635 15.3639 3.87954L14.3686 4.87485C14.0254 5.21844 13.4686 5.21844 13.1246 4.87485Z" fill="currentColor" />
                <path d="M4.87579 13.1255C4.5322 12.7816 3.97535 12.7816 3.63176 13.1255L2.63645 14.1205C2.29326 14.4636 2.29286 15.0213 2.63645 15.3645C2.98005 15.7077 3.53729 15.7077 3.88048 15.3645L4.87579 14.3688C5.21938 14.0256 5.21938 13.4683 4.87579 13.1255Z" fill="currentColor" />
                <path d="M14.832 8.99989C14.832 8.514 15.2258 8.12018 15.7117 8.12018H17.1192C17.6051 8.12018 17.9989 8.514 17.9989 8.99989C17.9989 9.48578 17.6051 9.8792 17.1192 9.8792H15.7117C15.2258 9.8792 14.832 9.48578 14.832 8.99989Z" fill="currentColor" />
                <path d="M3.16648 8.99995C3.16648 8.51406 2.77267 8.12024 2.28677 8.12024H0.879712C0.393817 8.12024 0 8.51406 0 8.99995C0 9.48584 0.393817 9.87926 0.879712 9.87926H2.28717C2.77267 9.87926 3.16648 9.48584 3.16648 8.99995Z" fill="currentColor" />
                <path d="M13.1249 13.1255C13.4685 12.7823 14.0257 12.7823 14.3689 13.1255L15.3642 14.1208C15.7078 14.4636 15.7078 15.0212 15.3642 15.3644C15.0206 15.7076 14.4638 15.7076 14.1202 15.3644L13.1249 14.3691C12.7813 14.0255 12.7813 13.4687 13.1249 13.1255Z" fill="currentColor" />
                <path d="M4.87594 4.87487C5.21953 4.53128 5.21953 3.97443 4.87594 3.63084L3.88063 2.63593C3.53704 2.29234 2.9802 2.29234 2.6366 2.63593C2.29301 2.97913 2.29301 3.53637 2.6366 3.87956L3.63191 4.87487C3.9755 5.21886 4.53235 5.21886 4.87594 4.87487Z" fill="currentColor" />
              </svg>
            </button>
            <Link to="/contact" className="bg-true-darkgray h-8 w-8 inline-flex justify-center items-center p-2 rounded-full">
              <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.3904 0C14.5824 0 15.7291 0.471111 16.5727 1.31644C17.4171 2.16 17.8891 3.29778 17.8891 4.48889V11.5111C17.8891 13.9911 15.8713 16 13.3904 16H4.60911C2.12822 16 0.111328 13.9911 0.111328 11.5111V4.48889C0.111328 2.00889 2.11933 0 4.60911 0H13.3904ZM14.8047 5.81363L14.8758 5.74252C15.0883 5.48474 15.0883 5.1114 14.866 4.85363C14.7425 4.72118 14.5727 4.64029 14.3958 4.62251C14.2092 4.61274 14.0314 4.67585 13.8972 4.80029L9.88915 8.00029C9.3736 8.42785 8.63493 8.42785 8.11137 8.00029L4.11137 4.80029C3.83493 4.59585 3.45271 4.62251 3.22249 4.86251C2.98249 5.10251 2.95582 5.48474 3.15937 5.7514L3.27582 5.86696L7.32026 9.02252C7.81804 9.41363 8.4216 9.62696 9.0536 9.62696C9.68382 9.62696 10.298 9.41363 10.7949 9.02252L14.8047 5.81363Z" fill="currentColor"/>
              </svg>
            </Link>

          </div>

        </div>

      </div>

      <div className="border-b border-sidebar-seperator"/>

              </div>
  )
}

export default Navbar
