import React, { useEffect, useState } from 'react'

function Accordion ({ title, children, onToggle, open = false }) {
  const [isOpen, setIsOpen] = useState(open)

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  function toggle () {
    const status = !isOpen
    setIsOpen(status)
    onToggle(status)
  }

  return (
    <div className="flex flex-col w-full" onClick={toggle}>
      <div className="w-full flex justify-between items-center mb-4">
        <strong className="w-4/5">{title}</strong>
        <button className="h-13 w-13 bg-true-white dark:bg-true-dark inline-flex justify-center items-center rounded-xs">
          {isOpen && <svg className="text-true-paragraph-color" width="20" height="4" viewBox="0 0 20 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.2085 1.9998C19.2084 2.83706 18.5912 3.53019 17.7871 3.64927L17.5406 3.66735L2.4583 3.66697C1.53732 3.66695 0.790847 2.92033 0.791016 1.99934C0.791169 1.16208 1.40833 0.468949 2.21249 0.349862L2.45892 0.331787L17.5412 0.332162C18.4622 0.332185 19.2087 1.07881 19.2085 1.9998Z" fill="currentColor" />
          </svg>
          }
          {!isOpen && <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g className="text-true-paragraph-color">
              <path d="M17.5388 8.33123C18.4604 8.33123 19.2075 9.07837 19.2075 10C19.2075 10.8379 18.5901 11.5315 17.7854 11.6507L17.5388 11.6688H2.4598C1.53816 11.6688 0.791016 10.9217 0.791016 10C0.791016 9.16216 1.40849 8.46852 2.2132 8.34932L2.4598 8.33123H17.5388Z" fill="currentColor" />
              <path d="M9.99951 0.79126C10.8368 0.791414 11.5299 1.40857 11.649 2.21273L11.6671 2.45916L11.6667 17.5415C11.6667 18.4625 10.92 19.2089 9.99905 19.2088C9.16179 19.2086 8.46866 18.5915 8.34957 17.7873L8.3315 17.5409L8.33187 2.45855C8.33189 1.53756 9.07852 0.791091 9.99951 0.79126Z" fill="currentColor" />
            </g>
          </svg>

          }
        </button>
      </div>

      {isOpen && <div className="bg-true-white  dark:bg-true-dark p-6 rounded-accordion-content mb-4">
        {children}
      </div>}
    </div>
  )
}

export default Accordion
