import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Navbar from '../components/Navbar'
import AppLayout from '../layouts/AppLayout'
import BackButton from '../components/BackButton'

const AdService = function () {
  const { t } = useTranslation()
  const [, setTheme] = useState(localStorage.getItem('theme'))

  return (
        <AppLayout title={t('ads_service')}>
            <Navbar setTheme={setTheme}/>
            <div className="header-gradient bg-section rounded-br-medium md:rounded-br-large relative">
                <div className="container">
                    <div className="flex flex-col-reverse md:flex-row justify-between py-8 md:py-12">

                        <div className="flex flex-col">
                            <BackButton/>

                            <h3 className="mt-4 text-2xl md:text-4xl font-bold dark:text-true-primary text-true-secondary">{t('ads_service')}</h3>
                            <p className="mt-9 text-base md:text-xl">{t('ads_service_paragraph_1')}</p>

                        </div>
                    </div>
                </div>
                <div className="container pb-12">
                    <p className="text-base md:text-xl">{t('ads_service_paragraph_2')}</p>
                    <p className="mt-9 text-base md:text-xl">{t('ads_service_paragraph_3')}</p>
                    <p className="mt-9 text-base md:text-xl">{t('ads_service_paragraph_4')}</p>
                </div>
            </div>
        </AppLayout>
  )
}

export default AdService
