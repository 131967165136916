import React from 'react'
import { useTranslation } from 'react-i18next'
import Navbar from '../components/Navbar'
import AppLayout from '../layouts/AppLayout'
import BackButton from '../components/BackButton'

const StrategicPartners = function () {
  const { t } = useTranslation()
  const partnerImages = [
    'home-partners__1',
    'home-partners__2',
    'home-partners__3',
    'home-partners__4',
    'home-partners__5'
  ]

  return (
        <AppLayout title={t('strategic_partners')}>
            <Navbar/>
            <div className="header-gradient bg-section rounded-br-medium md:rounded-br-large relative">
                <div className="container">
                    <div className="flex flex-col-reverse md:flex-row justify-between py-8 md:py-12">

                        <div className="flex flex-col max-w-550">
                            <BackButton/>

                            <h3 className="mt-4 text-2xl md:text-4xl font-bold dark:text-true-primary text-true-secondary">{t('strategic_partners')}</h3>
                            <p className="mt-9 text-base md:text-2xl">{t('strategic_partners_paragraph_1')}</p>
                            <div className="grid grid-cols-2 md:grid-cols-3 gap-3 mt-6">
                                {partnerImages.map((partnerImage, index) => {
                                  return (
                                        <div className="w-36 h-28 bg-input-bg rounded-3xl" key={index}>
                                            <div
                                                className={'w-36 h-28 home-partners ' + partnerImage}/>
                                        </div>
                                  )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
  )
}

export default StrategicPartners
