import React, { useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import Navbar from '../components/Navbar'
import AppLayout from '../layouts/AppLayout'
import BackButton from '../components/BackButton'
import Banner from '../assets/HomeLogoWave.svg'
import TokenomicsDarkSvg from '../assets/TokenomicsDark.svg'
import TokenomicsLightSvg from '../assets/TokenomicsLight.svg'
import TokenomicsLightMobilSvg from '../assets/TokenomicsLightMobil.svg'
import TokenomicsDarkMobilSvg from '../assets/TokenomicsDarkMobil.svg'
import WarningSvg from '../svg/WarningSvg'

const WhatIsTrueFeedBack = function () {
  const { t } = useTranslation()
  const [theme, setTheme] = useState(localStorage.getItem('theme'))

  return (
        <AppLayout title={t('tokenomics')}>
            <Navbar setTheme={setTheme}/>
            <div className="header-gradient bg-section rounded-br-medium md:rounded-br-large relative">
                <div className="container">
                    <div className="flex flex-col-reverse md:flex-row justify-between py-8 md:py-12">

                        <div className="flex flex-col max-w-550">
                            <BackButton/>

                            <h3 className="mt-4 text-2xl md:text-4xl font-bold dark:text-true-primary text-true-secondary">{t('tokenomics')}</h3>
                            <p className="mt-9 text-base md:text-2xl">{t('tokenomics_paragraph_1')}</p>
                            <img src={theme === 'light' ? TokenomicsLightSvg : TokenomicsDarkSvg} alt=""
                                 className="mt-16 hidden md:block"/>
                            <img src={theme === 'light' ? TokenomicsLightMobilSvg : TokenomicsDarkMobilSvg} alt=""
                                 className="mt-16 md:hidden"/>
                            <div className="flex px-2 dark:bg-true-dark bg-true-secondary md:max-w-fit-content mt-9 rounded-xs">
                                <WarningSvg theme={theme} className="self-center" />
                                <div className="text-xs p-3 text-muted">
                                    <Trans i18nKey="tokenomics_caution" components={{
                                      strong: <span className="font-bold text-sm dark:text-true-white"/>,
                                      a: <a />
                                    }}/>
                                </div>
                            </div>
                        </div>

                        <img className="mb-8 px-8 md:px-0 md:self-start md:w-96" src={Banner} alt=""/>
                    </div>
                </div>
            </div>
        </AppLayout>
  )
}

export default WhatIsTrueFeedBack
