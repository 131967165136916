import React from 'react'
import { Panel } from 'rsuite'
import { useTranslation } from 'react-i18next'

export const SignInWithBlackStar = () => {
  const { t } = useTranslation()
  return (
    <div className='flex items-center justify-around'>
        <Panel
        style={{
          border: '1px solid #046EFF',
          borderRadius: '10px'
        }}
        >
                <text className = 'dark:text-true-white text-true-black'
                style={{
                  fontWeight: '600',
                  fontSize: '15px'
                }}>
                    Black
                  </text>

              <text className='text-true-blue dark:text-true-white' style={{
                fontWeight: '600',
                fontSize: '15px'
              }}>
                  Star {' '}
                </text>

                <text style={{

                  fontWeight: '600',
                  fontSize: '15px',
                  color: '#E6AE11'
                }}>
                 {t('Account With Sign In')}
                </text>
        </Panel>
    </div>
  )
}
