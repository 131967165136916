import React from 'react'
import Cookie from '../components/Cookie'
import Footer from '../components/Footer'
import { Helmet } from 'react-helmet-async'

function AppLayout ({ title = '', children }) {
  return (
        <>
            <Helmet>
                <title>{title ? `${title} | TrueFeedBack` : 'TrueFeedBack'}</title>
            </Helmet>
              {children}
            <Footer />
            <Cookie />
        </>
  )
}

export default AppLayout
