import { useEffect, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Button, Input, Modal, Notification, toaster } from 'rsuite'
import 'rsuite/dist/rsuite.min.css'
import Navbar from '../components/Navbar'
import { SignInWithBlackStar } from '../components/SignInWithBlackStar'
import { useStorken } from '../data'
import Backend from '../data/Backend'
import AppLayout from '../layouts/AppLayout'


function SignIn () {
  const ErrorMessage = (
    <Notification type="error" header="Hata" closable>
      <p>Giriş bilgilerinizi ve doğrulama kodunuzu kontrol ediniz</p>
    </Notification>
  )
  const { t } = useTranslation()
  const [email, setEmail] = useState()
  const [loading, setLoading] = useState(false)
  const [captchaShow, showCaptcha] = useState(false)
  const [captcha, setCaptcha] = useState(false)
  const [, setCredentials] = useStorken('credentials')
  const [, setBalance] = useStorken('balance')
  const history = useHistory()
  const [signInModal, setSignInModal] = useState()
  const [confirmCode, setConfirmCode] = useState()
  const successSignInOpenModal = () => setSignInModal(true)
  const successSignInCloseModal = () => {
    setConfirmCode('')
    setSignInModal(false)
  }

  const handleSignIn = async () => {
    setLoading(true)
    Backend.User.signin({ email, code: confirmCode }).then(
      (response) => {
        setLoading(false)
        if (response.status === 202) {
          return successSignInOpenModal()
        } else if (response.status === 201) {
          const { credentials, balance } = response.data
          setCredentials(credentials)
          setBalance(balance)
          history.push('/claim')
        } else {
          toaster.push(ErrorMessage, { placement: 'topCenter' })
        }
      }
    )

    // const result = (email === null && password === null)
    // if (!result) {
    //   toaster.push(ErrorMessage, { placement: 'topCenter' })
    // }
  }
  const handleSuccessConfirmCode = () => {
    handleSignIn()
  }

  useEffect(() => {
    if (captcha) {
      handleSignIn()
      successSignInOpenModal()
    }
  }, [captcha])

  return (
    <AppLayout>
      <Navbar />
      <form
        class="flex flex-col mt-8 space-y-6 justify-center align-center"
        onSubmit={handleSignIn}
      >
        <div class="flex justify-center items-center md:w-2/6 lg:w-72 self-center">
          <h1
            style={{
              fontStyle: 'normal',
              fontWeight: '800',
              fontSize: '21px',
              lineHeight: '34px'
            }}
          >
            {t('Wallet Delivery Request')}
          </h1>
        </div>
        <SignInWithBlackStar />
        <Modal
          backdrop={'static'}
          open={signInModal}
          onClose={successSignInCloseModal}
        >
          <text>{t('Enter the confirmation code sent to your e-mail.')}</text>
          <Modal.Body>
            <Input
              value={confirmCode}
              onChange={(event) => setConfirmCode(event)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleSuccessConfirmCode} loading={loading}>
              {t('Approve')}
            </Button>
            <Button onClick={successSignInCloseModal}>{t('Cancel')}</Button>
          </Modal.Footer>
        </Modal>

        <div class="flex justify-center items-center md:w-2/6 lg:w-72 self-center">
          <Input
            value={email}
            onChange={(event) => setEmail(event)}
            required
            placeholder={t('Email or Username')}
            id="email-adress"
            name="email"
            type="email"
            autoComplete="email"
          />
        </div>
        {captchaShow && (
          <div className="flex justify-center items-center sm:flex">
            <ReCAPTCHA
              sitekey="6LdQFIcfAAAAAI8s_Pl6hyoe6MXmuu802OsvIrjV"
              onChange={() => setCaptcha(true)}
              onExpired={() => setCaptcha(false)}
              onErrored={() => setCaptcha(false)}
            />
          </div>
        )}
        <div className="flex justify-around items-center sm:flex">
          <Button
            type='button'
            onClick={captcha ? handleSignIn : showCaptcha}
            style={{
              borderRadius: '10px',
              border: '1px solid #046EFF'
            }}
            loading={loading}
          >
            {t('Sign In')}
          </Button>
        </div>
      </form>
    </AppLayout>
  )
}

export default SignIn
