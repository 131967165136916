/* eslint-disable indent */
import { createPopper } from '@popperjs/core'
import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import useClickOutside from '../hooks/useClickOutside'
import DropdownMenu from './DropdownMenu'
import { useTranslation } from 'react-i18next'

function MenuItem ({ className, title, dropdown, to, level = 0 }) {
  const { t } = useTranslation()
  title = t(title)
  const [showDropdown, setShowDropdown] = useState(false)
  const innerRef = useClickOutside((ev) => {
    closeDropdown()
  })
  const isExternalLink = to ? to.indexOf('http') !== -1 : false
  const menuItemRef = useRef()
  const dropdownMenuRef = useRef()

  const openDropdown = () => {
    createPopper(menuItemRef.current, dropdownMenuRef.current, {
      placement: parseInt(level) === 0 ? 'bottom-start' : 'right-start',
      modifiers: [
        level > 0
          ? {
              name: 'offset',
              options: {
                offset: [-15, 20]
              }
            }
          : {
              name: 'offset',
              options: {
                offset: [0, 3]
              }
            }
      ]
    })
    setShowDropdown(true)
  }
  const closeDropdown = () => {
    setShowDropdown(false)
  }

  return (
    <div className="relative" ref={innerRef}>
      {isExternalLink
       ? (
        <a
          href={to}
          target="_blank"
          rel="noreferrer"
          className={[
            'font-bold dark:hover:bg-true-menu-active relative justify-between text-sm w-full hover:dark:text-true-secondary inline-flex items-center gap-1 px-4 py-2 rounded-lg dark:text-muted',
            className
          ]}
        >
          {title}
        </a>
      )
: (
        <Link
          ref={menuItemRef}
          onClick={(e) => {
            dropdown && (showDropdown ? closeDropdown() : openDropdown())
          }}
          className={[
            'font-bold dark:hover:bg-true-menu-active text-true-black hover:text-true-black  relative justify-between text-sm w-full inline-flex items-center gap-1 px-4 py-2 rounded-lg dark:text-muted focus:no-underline hover:no-underline',
            showDropdown
              ? 'dark:text-true-secondary bg-true-menu-active  focus:text-true-secondary '
              : '',
            className
          ].join(' ')}
          to={to || '#'}
        >
          {title}

          {dropdown &&
            ((level === 0 && (
              <svg
                width="8"
                height="5"
                viewBox="0 0 8 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.34375 -8.6849e-08L6.92954 -2.55436e-07C7.82044 -2.55436e-07 8.26661 1.07714 7.63664 1.70711L5.05086 4.29289C4.66033 4.68342 4.02717 4.68342 3.63664 4.29289L1.05086 1.70711C0.420894 1.07714 0.867059 -2.55436e-07 1.75796 -2.55436e-07L4.34375 -8.6849e-08Z"
                  fill="currentColor"
                />
              </svg>
            )) ||
              (level > 0 && (
                <svg
                  width="8"
                  height="12"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.375 0.75L6.625 6L1.375 11.25"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )))}
        </Link>
      )}
      {dropdown && (
        <DropdownMenu ref={dropdownMenuRef} show={showDropdown}>
          {dropdown.map((item, i) => {
            return (
              <MenuItem
                to={item.href}
                className="no-underline hover:text-true-black "
                onClick={(e) => {
                  if (item.items) e.preventDefault()
                }}
                title={item.title}
                key={i}
                dropdown={item?.items}
                level={level + 1}
              />
            )
          })}
        </DropdownMenu>
      )}
    </div>
  )
}
export default MenuItem
