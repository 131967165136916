import Web3Instance from 'web3'
import apisauce from 'apisauce'
import { createStorken } from 'storken'

export const Web3 = new Web3Instance(Web3Instance.givenProvider)

export const { NODE_ENV } = process.env

export const INDEV = NODE_ENV === 'development'

export const BACKEND_URL = INDEV ? 'http://localhost:33500/web' : 'https://api.truefeedback.io/web'

export const api = apisauce.create({
  baseURL: BACKEND_URL
})

export const { useStorken } = createStorken({})

export const Data = {
  NODE_ENV,
  INDEV,
  BACKEND_URL,
  Web3,
  api
}

export default Data
