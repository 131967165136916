import React from 'react'
import Banner from '../assets/company-banner.svg'
import Logo1 from '../assets/blackStarOption.svg'
import Logo2 from '../assets/blackStarSubLight.svg'
import Logo3 from '../assets/veriHizmetleriLight.svg'
import BackButton from '../components/BackButton'
import OutlineButton from '../components/OutlineButton'
import AppLayout from '../layouts/AppLayout'
import Navbar from '../components/Navbar'
import { useTranslation } from 'react-i18next'

function Company () {
  const { t } = useTranslation()
  const detailItems = [
    {
      logo: Logo1,
      title: 'BlackStar',
      paragraph: 'company_detail_1_paragraph',
      link: '/blackstar'
    },
    {
      logo: Logo2,
      title: 'company_detail_2_title',
      paragraph: 'company_detail_2_paragraph',
      link: '/blackstar'
    },
    {
      logo: Logo3,
      title: 'company_detail_3_title',
      paragraph: 'company_detail_3_paragraph',
      link: '/data-analyze'
    }
  ]
  return (
        <AppLayout>
            <Navbar/>
            <div className="header-gradient bg-section rounded-br-medium md:rounded-br-large">
                <div className="container pb-12">
                    <div className="flex flex-col-reverse md:flex-row justify-between py-8 md:py-12">

                        <div className="flex flex-col max-w-550">
                            <BackButton/>

                            <h3 className="mt-4 text-2xl md:text-4xl font-bold dark:text-true-primary text-true-secondary">{t('TrueFeedBack')}</h3>
                            <p className="mt-9 text-2xl">{t('company_header_paragraph_1')}</p>

                            <p className="mt-7 text-base">{t('company_header_paragraph_2')}</p>

                            <p className="mt-7 text-base">{t('company_header_paragraph_3')}</p>

                        </div>

                        <img className="mb-8 px-8 md:px-0" src={Banner} alt=""/>
                    </div>

                    {
                        detailItems.map((item, index) => {
                          return (
                                <div className="max-w-4xl mt-8" key={index}>
                                    <div className="w-full flex flex-col md:flex-row justify-between items-center">
                                        <img src={item.logo} alt=""/>
                                        <div className="w-full max-w-lg dark:text-true-white">
                                            <div
                                                className="font-black text-heading-sm md:text-banner-sm">{t(item.title)}
                                            </div>
                                            <p className="text-paragraph-sm md:text-paragraph-lg my-4">{t(item.paragraph)}</p>
                                            <OutlineButton
                                                href={item.link}
                                                className="w-full mt-8"
                                                spacing={false}
                                                fill={false}
                                            >{t('Detail')}</OutlineButton>
                                        </div>

                                    </div>
                                </div>
                          )
                        })
                    }

                </div>
            </div>
        </AppLayout>
  )
}

export default Company
