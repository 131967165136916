import React from 'react'
import LibraryHeader from '../assets/LibraryHeader.svg'
import OutlineButton from '../components/OutlineButton'
import AppLayout from '../layouts/AppLayout'
import { useTranslation, Trans, getI18n } from 'react-i18next'
import Navbar from '../components/Navbar'
import LibraryGuideSvg from '../assets/LibraryGuide.svg'
import LibraryTokenSvg from '../assets/LibraryToken.svg'
import LibraryWhitepaperSvg from '../assets/LibraryWhitepaper.svg'

function Library () {
  const { t } = useTranslation()

  const items = [
    {
      logo: LibraryGuideSvg,
      title: 'blackstar_usage_guide',
      paragraph: 'library_usage_guide_paragraph',
      link: {
        tr: '/library/kullanim_rehberi_tr.pdf',
        en: false
      }
    },
    {
      logo: LibraryTokenSvg,
      title: 'blackstar_token_buy_guide',
      paragraph: 'library_usage_guide_paragraph',
      link: {
        tr: '/library/tfbx_alim_rehberi_tr.pdf',
        en: '/library/tfbx_alim_rehberi_en.pdf'
      }
    },
    {
      logo: LibraryWhitepaperSvg,
      title: 'white_paper_strong',
      paragraph: 'library_usage_guide_paragraph',
      link: {
        tr: '/library/whitepaper_tr.pdf',
        en: '/library/whitepaper_en.pdf'
      }
    }
  ]

  return (
        <AppLayout title={t('LIBRARY')}>
            <Navbar/>
            <div className="header-gradient bg-section rounded-br-medium md:rounded-br-large">
                <div className="container">
                    <div className="flex flex-col-reverse justify-between md:flex-row pb-12">

                        <div className="flex flex-col mt-12 md:w-2/3">
                            <h3 className="text-2xl md:text-4xl font-bold gradient-title">
                                <Trans
                                    i18nKey="welcome_to_tfb_library"
                                    components={{
                                      strong: <span className="text-true-secondary webkit-fill-color-secondary"/>
                                    }}
                                />
                            </h3>

                            <p className="mt-6 text-sm md:text-base">{t('library_header_paragraph_1')}</p>
                            <p className="mt-6 text-sm md:text-base">{t('library_header_paragraph_2')}</p>
                        </div>

                        <img src={LibraryHeader} alt="header-library-svg"/>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="grid grid-cols-1 md:grid-cols-3">
                    {items.map((item, index) => {
                      return (
                            <div key={index} className="text-center md:px-12">
                                <div className="flex justify-center pt-6">
                                    <img src={item.logo} alt="" className="h-48 w-48"/>
                                </div>
                                <div className="md:text-2xl mt-6">
                                    <Trans i18nKey={item.title} components={{
                                      strong: <span className="font-bold"/>
                                    }}/>
                                </div>
                                { item.link[getI18n().language]
                                  ? <OutlineButton href={item.link[getI18n().language]} fill={false}>{t('download_now')}</OutlineButton>
                                  : <OutlineButton>Coming Soon</OutlineButton>
                                }
                            </div>
                      )
                    })}
                </div>
            </div>
        </AppLayout>
  )
}

export default Library
