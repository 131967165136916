import React from 'react'

function Spinner () {
  return (
    <div className="h-screen">

    </div>
  )
}

export default Spinner
