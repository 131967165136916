import React from 'react'

const WarningSvg = ({ theme, className }) => {
  const colors = {
    dark: '#B3B3B3',
    light: '#191A1B'
  }

  return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"
             className={className}>
            <path
                d="M24 11.539C23.405 11.8 22.771 11.973 22.11 12.057C22.79 11.651 23.309 11.013 23.553 10.244C22.919 10.622 22.219 10.889 21.473 11.038C20.871 10.397 20.013 10 19.077 10C17.261 10 15.799 11.474 15.799 13.281C15.799 13.541 15.821 13.791 15.875 14.029C13.148 13.896 10.735 12.589 9.114 10.598C8.831 11.089 8.665 11.651 8.665 12.256C8.665 13.392 9.25 14.399 10.122 14.982C9.595 14.972 9.078 14.819 8.64 14.578C8.64 14.588 8.64 14.601 8.64 14.614C8.64 16.208 9.777 17.532 11.268 17.837C11.001 17.91 10.71 17.945 10.408 17.945C10.198 17.945 9.986 17.933 9.787 17.889C10.212 19.188 11.418 20.143 12.852 20.174C11.736 21.047 10.319 21.573 8.785 21.573C8.516 21.573 8.258 21.561 8 21.528C9.453 22.465 11.175 23 13.032 23C19.068 23 22.368 18 22.368 13.666C22.368 13.521 22.363 13.381 22.356 13.242C23.007 12.78 23.554 12.203 24 11.539Z"
                fill={colors[theme]}/>
        </svg>
  )
}

export default WarningSvg
