import React from 'react'
import Navbar from '../components/Navbar'

function Header ({ children, className }) {
  const classes = [
    'w-full header-gradient bg-section',
    className
  ].join(' ')

  return (
    <div className={classes}>
      <Navbar />

      {children}
    </div >
  )
}

export default Header
