import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import navbarItems from '../data/navbar.json'
import useScrollPosition from '../hooks/useScrollPosition'

function MobileMenu ({ setMenuShow, show }) {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)
  const [selectedMenuItems, setSelectedMenuItems] = useState(navbarItems)
  const [parentMenuItems, setParentMenuItems] = useState([])
  const [selectedMenuTitle, setSelectedMenuTitle] = useState(null)
  const [parentMenuTitle, setParentMenuTitle] = useState([])
  const scrollPosition = useScrollPosition()
  const setCurrentMenuItems = (items, title) => {
    setParentMenuItems([...parentMenuItems, items])
    setParentMenuTitle([...parentMenuTitle, title])
    setSelectedMenuItems(items)
    setSelectedMenuTitle(title)
  }

  const parentMenu = () => {
    if (parentMenuItems.length >= 2) {
      setSelectedMenuItems(parentMenuItems.slice(-2)[0])
      setParentMenuItems(parentMenuItems.slice(0, -1))

      setSelectedMenuTitle(parentMenuTitle.slice(-2)[0])
      setParentMenuTitle(parentMenuTitle.slice(0, -1))
    } else {
      setSelectedMenuItems(navbarItems)
      setParentMenuItems([])

      setSelectedMenuTitle(null)
      setParentMenuTitle([])
    }
  }

  useEffect(() => {
    setVisible(scrollPosition > 100)
  }, [scrollPosition])

  useEffect(() => {
    const bodyClassList = document.body.classList

    if (show) {
      bodyClassList.add('overflow-hidden')
    } else {
      bodyClassList.remove('overflow-hidden')
    }

    return () => {
      bodyClassList.remove('overflow-hidden')
    }
  }, [show])
  return (
        <div className={['sm:hidden fixed mobile-navbar w-full', (visible || show ? 'block' : ''), (show ? 'min-h-screen h-full bg-mobile-menu-open' : 'bg-mobile-menu-collapsed')].join(' ')}>
            <div className="flex flex-col container mx-auto p-6 h-full overflow-y-auto">
                {!selectedMenuTitle &&
                    <div className="flex justify-between items-center">
                        <Link to="/" className="max-w-brand-sm">
                            <svg className="w-full" viewBox="0 0 150 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M49.7605 7.50863C28.8211 -6.09022 7.86208 1.84244 0 7.50863C1.08226 32.9653 17.2338 46.8974 25.1743 50.6814C47.196 35.5298 50.7408 15.5864 49.7605 7.50863Z" fill="#060606" />
                                <path d="M8.34729 13.0736C21.8755 5.07432 36.0509 9.34846 41.8739 12.956C41.9915 16.0146 41.521 18.5634 41.2269 19.3672C30.7807 11.9326 18.9151 14.3088 14.288 16.4263C14.288 18.0262 15.1899 20.8573 15.6408 22.0729C27.828 17.2262 36.639 22.2493 39.5211 25.3667C38.9565 26.8725 37.4429 29.484 36.7567 30.6016C29.4632 23.8727 21.2482 25.6804 18.0524 27.4254C21.8168 33.7307 27.7771 37.2284 30.2866 38.1892L25.1106 42.0712C11.053 34.2483 8.17086 19.5437 8.34729 13.0736Z" fill="#EDB400" />
                            </svg>
                        </Link>

                        <div className="nav">
                            <button className="bg-true-secondary rounded-full inline-flex items-center justify-center p-2" onClick={() => (setMenuShow(!show))}>
                                {
                                    (
                                      show &&
                                        <svg width="16" height="16" viewBox="-2 -2 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.5321 9.26846C12.156 9.89176 12.156 10.9 11.5321 11.5215C11.2202 11.8332 10.8165 12 10.3945 12C9.99083 12 9.58716 11.8533 9.27523 11.5215L6.00917 8.2785L2.74312 11.5215C2.43119 11.8332 2.02752 12 1.6055 12C1.20183 12 0.779817 11.8332 0.46789 11.5215C-0.155963 10.9 -0.155963 9.89176 0.46789 9.25012L3.73395 6.00527L0.46789 2.74025C-0.155963 2.11878 -0.155963 1.09216 0.46789 0.468854C1.09174 -0.156285 2.10092 -0.156285 2.74312 0.468854L6.00917 3.73021L9.25688 0.468854C9.89908 -0.156285 10.9083 -0.156285 11.5321 0.468854C12.156 1.09216 12.156 2.11878 11.5321 2.74025L8.26606 6.00527L11.5321 9.26846Z" fill="#282A2B" />
                                        </svg>
                                    ) ||
                                    (
                                      !show &&
                                        <svg width="16" height="16" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="10.229" height="2.27936" rx="1.13968" fill="#282A2B" />
                                            <rect y="4.7486" width="15.1" height="2.27936" rx="1.13968" fill="#282A2B" />
                                            <rect y="9.39062" width="15.1" height="2.27936" rx="1.13968" fill="#282A2B" />
                                        </svg>
                                    )
                                }
                            </button>
                        </div>
                    </div>
                }

                {selectedMenuTitle &&
                    <div className="flex flex-row items-center">
                        <button className="bg-true-secondary rounded-full inline-flex items-center justify-center p-2" onClick={() => (parentMenu())}>
                            {
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.6673 6.16658L3.52565 6.16658L8.18398 1.50825L7.00065 0.333252L0.333984 6.99992L7.00065 13.6666L8.17565 12.4916L3.52565 7.83325L13.6673 7.83325L13.6673 6.16658Z" fill="#191A1B" />
                                </svg>

                            }
                        </button>

                        <h3 className="text-true-secondary font-black mx-auto">{t(selectedMenuTitle)}</h3>
                    </div>
                }

                {show &&
                    <div className="flex flex-col justify-between h-full mt-10">
                        <div className="flex flex-col">

                            <span className="text-sm text-true-secondary py-4"><Trans>MENU</Trans></span>
                            {
                                selectedMenuItems.map((item, i) => {
                                  const isExternalLink = item.href ? item.href.indexOf('http') !== -1 : false
                                  const classes = 'py-2 flex justify-between items-center border-opacity-20 border-true-white' + (i < navbarItems.length - 1 ? ' border-b' : '')
                                  return isExternalLink
                                    ? (
                                            <a href={item.href} target="_blank" rel="noreferrer" key={i} className={classes}>{t(item.title)}</a>
                                      )
                                    : (
                                        <Link onClick={() => { setCurrentMenuItems(item.items, item.title) }} className={classes} to={item.href || '/'} key={i}>
                                            {t(item.title)}
                                            {Array.isArray(item.items) && <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.375 0.75L6.625 6L1.375 11.25" stroke="#636363" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>}
                                        </Link>
                                      )
                                })
                            }
                        </div>

                        <div className="flex flex-wrap justify-between mt-6">
                            <div className="flex-grow flex-shrink-0 w-full text-lg font-extrabold mb-5">
                                <Trans>DOWNLOAD_APP</Trans>
                            </div>

                            <a
                                href="https://apps.apple.com/app/tfb-blackstar/id1540246904"
                                target="_blank"
                                rel="noreferrer"
                                className="w-36"
                            >
                                <img src="/icons/download-ios.png" alt="App Store logo" />
                            </a>

                            <a
                                href="https://play.google.com/store/apps/details?id=com.trueefeedbackchain.bountypoll"
                                target="_blank"
                                rel="noreferrer"
                                className="w-36"
                            >
                                <img src="/icons/download-android.png" alt="Play Store Logo" />
                            </a>
                        </div>

                    </div>
                }
            </div>
        </div>
  )
}

export default MobileMenu
