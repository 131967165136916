import { api } from '.'

export const Backend = {
  User: {
    signin: ({ email, password, code }) =>
      api.post('/claim/signin', { email, password, code }),
    withdrawalRequest: ({ amount, address }, auth) =>
      api.post('/claim/request', { amount, address }, { headers: { auth } })
  }
}

export default Backend
