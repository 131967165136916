/* eslint-disable camelcase */
import React from 'react'
import { useTranslation, getI18n } from 'react-i18next'
import Navbar from '../components/Navbar'
import AppLayout from '../layouts/AppLayout'
import BackButton from '../components/BackButton'
import Banner from '../assets/blockchain-technologies_tr.png'
import Banner_en from '../assets/blockchain-technologies_en.png'

const BlockchainTechnologies = function () {
  const { t } = useTranslation()
  const lang = getI18n().language

  return (
        <AppLayout title={t('blockchain_technogies')}>
            <Navbar/>
            <div className="header-gradient bg-section rounded-br-medium md:rounded-br-large relative">
                <div className="container">
                    <div className="flex flex-col-reverse md:flex-row justify-between py-8 md:py-12">

                        <div className="col">
                            <BackButton/>

                            <h3 className="mt-4 text-2xl md:text-4xl font-bold dark:text-true-primary text-true-secondary">{t('blockchain_technogies')}</h3>
                            <img
                                className="mb-8 ml-8 px-8 md:px-0 sm:max-w-md float-right pt-4"
                                src={ lang === 'tr' ? Banner : Banner_en} alt=""
                            />

                            <p className="mt-9 text-base md:text-xl">{t('blockchain_technologies_paragraph_1')}</p>
                            <p className="mt-9 text-base md:text-xl">{t('blockchain_technologies_paragraph_2')}</p>
                            <p className="mt-9 text-base md:text-xl">{t('blockchain_technologies_paragraph_3')}</p>

                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
  )
}

export default BlockchainTechnologies
