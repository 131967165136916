import React from 'react'
import BackButton from '../components/BackButton'
import AppLayout from '../layouts/AppLayout'
import Navbar from '../components/Navbar'
import { useTranslation, Trans } from 'react-i18next'
import HomoLogoWaveSvg from '../assets/HomeLogoWave.svg'
import Q2Svg from '../assets/roadmap/Q2.svg'
import Q3Svg from '../assets/roadmap/Q3.svg'
import Q4Svg from '../assets/roadmap/Q4.svg'
import TimelinePointSvg from '../assets/roadmap/TimelinePoint.svg'
import Timeline1Svg from '../assets/roadmap/Timeline1.svg'
import Timeline2Svg from '../assets/roadmap/Timeline2.svg'

function Roadmap () {
  const { t } = useTranslation()

  return (
    <AppLayout title={t('roadmap')}>
      <Navbar />
      <div className='section-gradient-3 rounded-br-medium md:rounded-br-large relative pb-28'>
        <div className='container flex justify-between'>
          <div className='py-8 md:py-12'>
            <img src={HomoLogoWaveSvg} alt='' className='md:hidden' />

            <BackButton />
            <h3 className='mt-4 text-2xl md:text-4xl font-bold dark:text-true-primary text-true-secondary'>
              {t('roadmap')}
            </h3>

            <div className='mt-4 text-2xl md:text-4xlt'>
              <Trans
                i18nKey='roadmap_header_subtitle'
                components={{
                  strong: <span className='font-bold' />
                }}
              />
            </div>
          </div>
        </div>
        <div className='hidden md:block absolute right-0 top-0'>
          <div className='container'>
            <img src={HomoLogoWaveSvg} alt='' className='xl:-mr-24' />
          </div>
        </div>
        <div className='mt-20 container pb-18'>
          <div className='relative inline-block'>
            <img src={Q2Svg} alt='' className='w-96' />
            <div className='flex justify-center mt-8 lg:hidden'>
              <img src={TimelinePointSvg} alt='' className='' />
            </div>
            <div className='hidden absolute z-20 lg:flex items-start top-1/3 right-0 transform translate-x-full pl-8'>
              <img
                src={TimelinePointSvg}
                alt=''
                className='transform -translate-y-1/2'
              />
              <img src={Timeline1Svg} alt='' className='ml-4' />
            </div>
          </div>
          <div className='mt-6 text-2xl font-bold text-true-secondary dark:text-true-primary'>
            {t('2022 Q2')}
          </div>
          <div className='mt-6 text-sm'>
            <Trans i18nKey='q2_aims' />
          </div>
        </div>
      </div>
      <div className='section-gradient-4 rounded-tl-medium md:rounded-tl-large rounded-bl-medium md:rounded-bl-large relative pb-28'>
        <div className='container md:flex md:justify-end'>
          <div className='relative inline-block'>
            <div>
              <img src={Q3Svg} alt='' className='-mt-28 w-96' />
              <div className='flex justify-center mt-8 lg:hidden'>
                <img src={TimelinePointSvg} alt='' className='' />
              </div>
              <div className='hidden lg:block absolute z-20 top-0 left-0 transform -translate-x-full pr-14'>
                <div className='flex'>
                  <img
                    src={TimelinePointSvg}
                    alt=''
                    className='ml-auto transform -translate-x-1/2'
                  />
                </div>
                <img src={Timeline2Svg} alt='' className='mt-4' />
                <img
                  src={TimelinePointSvg}
                  alt=''
                  className='mt-4 transform -translate-x-1/3'
                />
              </div>
            </div>
            <div className='mt-6 text-2xl font-bold text-true-secondary dark:text-true-primary'>
              {t('2022 Q3')}
            </div>
            <div className='mt-6 text-sm'>
              <Trans i18nKey='q3_aims' />
            </div>
          </div>
        </div>
        <div className='container'>
          <img src={Q4Svg} alt='' className='w-96' />
          <div className='flex justify-center mt-8 lg:hidden'>
            <img src={TimelinePointSvg} alt='' className='' />
          </div>
          <div className='mt-6 text-2xl font-bold text-true-secondary dark:text-true-primary'>
            {t('2022 Q4')}
          </div>
          <div className='mt-6 text-sm'>
            <Trans i18nKey='q4_aims' />
          </div>
        </div>
      </div>
    </AppLayout>
  )
}

export default Roadmap
