import React, { useState } from 'react'
import Accordion from '../components/Accordion'
import BackButton from '../components/BackButton'
import AppLayout from '../layouts/AppLayout'
import Navbar from '../components/Navbar'
import { useTranslation } from 'react-i18next'
import FAQSvg from '../assets/LibraryFAQ.svg'

const faqItems = [
  {
    title: 'FAQ_WHAT_IS_TRUEFEEDBACK',
    detail: 'FAQ_WHAT_IS_TRUEFEEDBACK_ANSWER'
  },
  {
    title: 'FAQ_WHAT_MAKES_TRUEFEEDBACK_DIFFERENT',
    detail: 'FAQ_WHAT_MAKES_TRUEFEEDBACK_DIFFERENT_ANSWER'
  },
  {
    title: 'FAQ_WHAT_IS_TFBX_TOKEN',
    detail: 'FAQ_WHAT_IS_TFBX_TOKEN_ANSWER'
  },
  {
    title: 'FAQ_HOW_CAN_USERS_EARN_TFBX_WITH_TRUEFEEDBACK',
    detail: 'FAQ_HOW_CAN_USERS_EARN_TFBX_WITH_TRUEFEEDBACK_ANSWER'
  },
  {
    title: 'FAQ_HOW_DOES_DATA_ANALYSIS_WITH_TRUEFEEDBACK_BENEFIT_COMPANIES',
    detail: 'FAQ_HOW_DOES_DATA_ANALYSIS_WITH_TRUEFEEDBACK_BENEFIT_COMPANIES_ANSWER'
  }
]

function FAQ () {
  const { t } = useTranslation()
  const [openIndex, setOpenIndex] = useState(null)

  return (
        <AppLayout title={t('faq')}>
            <Navbar/>
            <div className="header-gradient bg-section rounded-br-medium md:rounded-br-large">
                <div className="container">
                    <div className="flex flex-col-reverse md:flex-row justify-between py-8 md:py-12">
                        <div className="flex flex-col max-w-screen-md w-full">
                            <BackButton/>

                            <h3 className="mt-4 mb-2 text-2xl md:text-4xl font-bold text-true-secondary">
                                {t('faq_long')}
                            </h3>

                            <div className="flex flex-col mt-5">
                                {faqItems.map(({ detail, title }, i) => (
                                    <Accordion
                                        key={`faq-${title}`}
                                        open={openIndex === i}
                                        title={t(title)}
                                        onToggle={(status) => setOpenIndex(status ? i : null)}
                                    >
                                        {t(detail)}
                                    </Accordion>
                                ))}
                            </div>

                        </div>
                        <div className="flex justify-center md:block">
                            <img src={FAQSvg} alt="" className="w-72 h-72"/>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
  )
}

export default FAQ
