import React from 'react'
import { useTranslation } from 'react-i18next'
import Navbar from '../components/Navbar'
import AppLayout from '../layouts/AppLayout'
import BackButton from '../components/BackButton'
import Banner from '../assets/Media.svg'

const Media = function () {
  const { t } = useTranslation()

  return (
        <AppLayout title={t('media')}>
            <Navbar/>
            <div className="header-gradient bg-section rounded-br-medium md:rounded-br-large relative">
                <div className="container">
                    <div className="flex flex-col-reverse md:flex-row justify-between py-8 md:py-12">

                        <div className="flex flex-col max-w-550">
                            <BackButton/>

                            <h3 className="mt-4 text-2xl md:text-4xl font-bold dark:text-true-primary text-true-secondary">{t('news_from_tfb')}</h3>
                            <p className="mt-9 text-base md:text-2xl">{t('media_paragraph_1')}</p>

                            <p className="mt-7 text-base">{t('media_paragraph_2')}</p>

                            <p className="mt-7 text-base">{t('media_paragraph_3')}</p>

                        </div>

                        <img className="mb-8 px-8 md:px-0" src={Banner} alt=""/>
                    </div>
                </div>
            </div>
            {/* <div className="hidden container md:flex justify-between"> */}
            {/*    <div className="max-w-550 mt-8"> */}
            {/*        <div className="font-bold text-2xl text-true-secondary dark:text-true-primary">{t('ecosystem')}</div> */}
            {/*        <ul className="list-disc list-inside mt-4"> */}
            {/*            <li>{t('what_is_truefeedback_list_1')}</li> */}
            {/*            <li>{t('what_is_truefeedback_list_2')}</li> */}
            {/*            <li>{t('what_is_truefeedback_list_3')}</li> */}
            {/*        </ul> */}
            {/*        <div className="mt-8 text-base">{t('what_is_truefeedback_paragraph_4')}</div> */}
            {/*    </div> */}
            {/*    <WhoWeAreLinks className="-mt-8 z-20 w-1/5"/> */}
            {/* </div> */}
        </AppLayout>
  )
}

export default Media
