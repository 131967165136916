export const HomePage = require('./Home').default
export const BlackstarPage = require('./Blackstar').default
export const LibraryPage = require('./Library').default
export const ContactPage = require('./Contact').default
export const FAQPage = require('./FAQ').default
export const CompanyPage = require('./Company').default
export const GenericPage = require('./Generic').default
export const RoadmapPage = require('./Roadmap').default
export const WhatIsTrueFeedBack = require('./WhatIsTrueFeedBack').default
export const Tokenomics = require('./Tokenomics').default
export const DataAnalyze = require('./DataAnalyze').default
export const StrategicPartners = require('./StrategicPartners').default
export const AdService = require('./AdService').default
export const ContentService = require('./ContentService').default
export const BlockchainTechnologies = require('./BlockchainTechnologies').default
export const Team = require('./Team').default
export const Media = require('./Media').default
export const SignIn = require('./SignIn').default
