
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Button, Input, InputGroup, Message, Modal, Notification, Panel, toaster } from 'rsuite'
import 'rsuite/dist/rsuite.min.css'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import { useStorken } from '../data'
import Backend from '../data/Backend'
function Claim () {
  const history = useHistory()
  const [value, setValue] = useState('')
  const [openModal, setOpenModal] = useState()
  const failedOpenModal = () => setOpenModal(true)
  const failedCloseModal = () => setOpenModal(false)
  const [openDialogModal, setOpenDialogModal] = useState()
  const dialogOpenModal = () => setOpenDialogModal(true)
  const dialogCloseModal = () => setOpenDialogModal(false)
  const [openEmptyModal, setOpenEmptyModal] = useState()
  const emptyOpenModal = () => setOpenEmptyModal(true)
  const emptyCloseModal = () => setOpenEmptyModal(false)
  const [backdrop, setBackdrop] = React.useState('static')
  const [openSuccessModal, setOpenSuccessModal] = useState()
  const successOpenModal = () => setOpenSuccessModal(true)
  const successCloseModal = () => {
    setOpenSuccessModal(false)
    history.replace('/')
  }
  const [walletValue, setWalletValue] = useState()
  const [credentials] = useStorken('credentials')
  const [balance] = useStorken('balance')
  const [loading, setLoading] = useState(false)

  const handleSendToWallet = () => {
    if (Number(value) > Number(balance)) {
      toaster.push(
        <Notification type='error' header={t('Unacceptable entry')}>
          <p>{t('The claim amount cannot be more than your balance')}</p>
        </Notification>
      )
    } else if (Number(value) <= Number(balance) && Number(value) > 0 && String(walletValue).length > 40) {
      dialogOpenModal()
    } else {
      emptyOpenModal()
    }
  }

  const handleSendSuccessWallet = () => {
    dialogCloseModal()
    setLoading(true)
    Backend.User.withdrawalRequest({ amount: value, address: walletValue }, credentials)
      .then(({ status, data, problem }) => {
        setLoading(false)
        console.log({ status, data })
        if (status === 201) {
          successOpenModal()
        } else {
          toaster.push(
            <Notification type='error' header='Error'>
              {data?.message || problem}
            </Notification>
          )
        }
      })
  }
  const { t } = useTranslation()

  useEffect(() => {
    if (!credentials) {
      history.replace('/signin')
    }
  }, [])

  return (
    <div>

      <Navbar />

      <Modal open={openModal} onClose={failedCloseModal}>
        {t('Insufficient balance!')}
      </Modal>
      <Modal open={openSuccessModal} onClose={successCloseModal}>
        <Modal.Header>
          <Modal.Title>{t('Successful')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('The submission request has been received successfully.')}
        </Modal.Body>
      </Modal>
      <Modal backdrop={backdrop} open={openDialogModal} onClose={dialogCloseModal}>
        <Modal.Header>
          <Modal.Title>{t('Confirm withdrawal')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('Please check the accuracy of the information. Incorrectly entered information is not within the scope of liability.')}
          <Modal.Footer>
            <Button onClick={handleSendSuccessWallet} loading={loading} appearance="default">
              {t('I accept OK')}
            </Button>
            <Button onClick={dialogCloseModal} appearance="subtle">
              {t('Back')}
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
      <Modal open={openEmptyModal} onClose={emptyCloseModal}>
        <Modal.Body>
          {t('Please fill in the requested information completely.')}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={emptyCloseModal} appearance="subtle">
            {t('Back')}
          </Button>
        </Modal.Footer>
      </Modal>
      <div className='flex items-center justify-around mt-8   '>

        <Panel style={{
          width: '60%',
          border: '1px solid #C4C4C4',
          backgroundColor: '#F8F8F8'
        }}>
          <div className='flex  flex-col items-center  md:flex '>
            <h1 style={{
              fontStyle: 'normal',
              fontWeight: '800',
              fontSize: '25px',
              lineHeight: '34px',
              marginBottom: '20px',
              color: '#333'
            }}> {t('Wallet Delivery Request')} </h1>
            <div class=" flex flex-col items-center " >
              <label style={{ fontSize: '18px' }} >{t('Balance')}</label>

              <InputGroup
                class=" w-400 sm:w-100 "
                style={{
                  border: '1px solid  #C4C4C4',
                  backgroundColor: '#f7f7fa',
                  height: '60px',
                  marginLeft: '10px'
                }}
              >
                <Input

                  style={{ fontSize: '20px', fontWeight: '500' }} readOnly value={balance}
                />
                <InputGroup.Addon >
                  TFBX
                </InputGroup.Addon>
              </InputGroup>

            </div>
            <div className='flex mt-10 flex-col items-center text-lg'>
              <label>{t('Wallet Address on Celo chain')}</label>

              <Input
                value={walletValue} onChange={(val) => setWalletValue(val)}
                style={{ fontSize: '20px', fontWeight: '500' }}
              />
            </div>
            <div className='flex flex-col mt-8  items-center  '>
              <label style={{ fontSize: '18px' }} >{t('Transfer Amount')}</label>
              <InputGroup
                class="w-400 sm:w-100 "
                style={{
                  border: '1px solid  #C4C4C4',
                  backgroundColor: '#F8F8F8',
                  height: '60px',
                  marginLeft: '10px'
                }}
              >
                <Input
                  type='text'
                  value={value}
                  onChange={(val) => setValue(String(val).replace(/[^0-9.]+/g, ''))}
                  style={{ fontSize: '20px', fontWeight: '500' }}
                />
                <InputGroup.Addon>
                  TFBX
                </InputGroup.Addon>
              </InputGroup>
            </div>
            <Message showIcon type="info" style={{ marginTop: 15 }}>
                {t('10% fee will be cut from that amount during transfer.')}
            </Message>
            <div
              className='flex items-center mt-8'
            >
              <Button
                isDisabled={loading}
                loading={loading}
                style={{
                  width: '200px',
                  marginLeft: '5px',
                  backgroundColor: '#EDBB1B'
                }}
                onClick={handleSendToWallet}
              >
                {t('Create Withdrawal Request')}
              </Button>
            </div>
          </div>
        </Panel>
      </div>
      <Footer />
    </div>
  )
}

export default Claim
