import React from 'react'
import { Trans } from 'react-i18next'
import OutlineButton from './OutlineButton'

export const ContractAddress = ({ src, alt = '', title = '', address = '', oldAddress, showConvert }) => (
  <>
  <div
    className="border-t flex flex-col md:flex-row items-center mt-8 pt-8 pr-0 md:pr-8"
    style={{ borderColor: '#bbb' }}
  >
    <div className="h-20 w-20 flex justify-center mr-0 md:mr-8 ml-0 md:ml-4">
      <img src={src} alt={alt} className="h-full" />
    </div>
    <div className="flex flex-col items-center mt-4 md:mt-0">
      <p><strong>{title}</strong></p>
      {oldAddress
        ? (
         <>
          <p className='text-xs md:text-base' style={{ marginBottom: 10 }}><span style={{ backgroundColor: '#fbcc5c', padding: 3, color: '#FFF', borderRadius: 5 }}><Trans>Old</Trans></span> {oldAddress}</p>
         </>
          )
        : null}
      <p className="text-xs md:text-base">{oldAddress ? <span style={{ backgroundColor: '#35d07f', padding: 3, color: '#FFF', borderRadius: 5 }}><Trans>New</Trans></span> : ''} {address}</p>
    </div>
  </div>
  {/* {showConvert
    ? (
    <OutlineButton href='/converter-swap' target='_self' className='!mb-5'>
      <Trans>Convert Old TFBXs</Trans>
    </OutlineButton>
      )
    : null} */}
</>
)

export default ContractAddress
