import React, { useState, useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import Navbar from '../components/Navbar'
import HomeEcosystem from '../components/HomeEcosystem'
import AppLayout from '../layouts/AppLayout'
import BackButton from '../components/BackButton'
import Banner2 from '../assets/ecosystem_2.png'

const WhatIsTrueFeedBack = function () {
  const { t, i18n } = useTranslation()
  const [theme, setTheme] = useState(localStorage.getItem('theme'))

  return (
        <AppLayout title={t('what_is_truefeedback')}>
            <Navbar setTheme={setTheme} />
            <div className="header-gradient bg-section rounded-br-medium md:rounded-br-large relative">
                <div className="container">
                    <div className="flex flex-col-reverse md:flex-row justify-between py-8 md:py-12">

                        <div className="col w-full">
                            <BackButton/>

                            <h3 className="mt-4 text-2xl md:text-4xl font-bold dark:text-true-primary text-true-secondary">{t('what_is_truefeedback')}</h3>
                            <HomeEcosystem
                                language={i18n.language}
                                theme={theme}
                                className="mb-8 ml-8 px-8 md:px-0 md:max-w-md float-right"
                            />

                            <p className="mt-9 text-base md:text-xl"><Trans i18nKey={'what_is_truefeedback_paragraph_1'}/></p>
                            <p className="mt-7 text-base md:text-xl"><Trans i18nKey={'what_is_truefeedback_paragraph_2'}/></p>
                            <p className="mt-7 text-base md:text-xl"><Trans i18nKey={'what_is_truefeedback_paragraph_3'}/></p>

                        </div>

                    </div>
                </div>
            </div>
            <div className="hidden container md:flex justify-between">
                <div className="max-w mt-8">
                    <h3 className="mt-4 text-2xl md:text-4xl font-bold dark:text-true-primary text-true-secondary">{t('ecosystem')}</h3>

                    <ul className="list-disc list-inside mt-4 text-base md:text-xl">
                        <li>{t('what_is_truefeedback_list_1')}</li>
                        <li>{t('what_is_truefeedback_list_2')}</li>
                        <li>{t('what_is_truefeedback_list_3')}</li>
                    </ul>
                    {i18n.language === 'tr' && (
                        <img className="m-8 px-8 md:px-0 max-w float-left" src={Banner2} alt=""/>
                    )}

                    <div className="mt-8 text-base md:text-xl">{t('what_is_truefeedback_paragraph_4')}</div>
                </div>
            </div>
        </AppLayout>
  )
}

export default WhatIsTrueFeedBack
