/* eslint-disable camelcase */
import React from 'react'
import { useTranslation, Trans, getI18n } from 'react-i18next'
import Navbar from '../components/Navbar'
import AppLayout from '../layouts/AppLayout'
import BackButton from '../components/BackButton'
import Banner from '../assets/truefeedback_ecosystem_tr.png'
import Banner_en from '../assets/truefeedback_ecosystem_en.png'
import DataAnalyze1Svg from '../assets/data-analyze/data-analyze-1.svg'
import DataAnalyze2Svg from '../assets/data-analyze/data-analyze-2.svg'
import DataAnalyze3Svg from '../assets/data-analyze/data-analyze-3.svg'
import DataAnalyze4Svg from '../assets/data-analyze/data-analyze-4.svg'
import DataAnalyze5Svg from '../assets/data-analyze/data-analyze-5.svg'

const DataAnalyze = function () {
  const { t } = useTranslation()
  const lang = getI18n().language

  const dataAnalyzeProcesses = [
    { svg: DataAnalyze1Svg, title: 'data_analyze_list_1_1' },
    { svg: DataAnalyze2Svg, title: 'data_analyze_list_1_2' },
    { svg: DataAnalyze3Svg, title: 'data_analyze_list_1_3' },
    { svg: DataAnalyze4Svg, title: 'data_analyze_list_1_4' },
    { svg: DataAnalyze5Svg, title: 'data_analyze_list_1_5' }
  ]

  return (
        <AppLayout title={t('data_analyze')}>
            <Navbar/>
            <div className="header-gradient bg-section rounded-br-medium md:rounded-br-large relative">
                <div className="container">
                    <div className="flex flex-col-reverse md:flex-row justify-between py-8 md:py-12">
                        <div className="col">
                            <BackButton/>

                            <h3 className="mt-4 text-2xl md:text-4xl font-bold dark:text-true-primary text-true-secondary">{t('data_analyze')}</h3>
                            <img
                                className="mb-8 ml-8 px-8 md:px-0 sm:max-w-md float-right pt-4"
                                src={lang === 'tr' ? Banner : Banner_en}
                                alt=""
                            />

                            <p className="mt-9 text-base md:text-xl">{t('data_analyze_paragraph_1')}</p>
                            <p className="mt-9 text-base md:text-xl">{t('data_analyze_paragraph_2')}</p>

                        </div>
                    </div>
                    <hr/>

                    <div className="mt-9 grid grid-cols-2 md:grid-cols-5 gap-5">
                        {dataAnalyzeProcesses.map((dataAnalyzeProcess, index) => {
                          return (
                                <div key={index} className="flex flex-col justify-center">
                                    <img src={dataAnalyzeProcess.svg} alt="" className="h-20"/>
                                    <div
                                        className="text-sm mt-4 text-center">{t(dataAnalyzeProcess.title)}</div>
                                </div>
                          )
                        })}
                    </div>
                    <hr className="mt-10"/>

                    <div className="max-w pb-12">
                        <p className="mt-9 text-base md:text-xl">{t('data_analyze_paragraph_3')}</p>
                        <ul className="list-disc list-inside mt-4">
                            <Trans i18nKey="data_analyze_list_2" components={{ li: <li/> }}/>
                        </ul>
                        <p className="mt-9 text-base md:text-xl">{t('data_analyze_paragraph_4')}</p>
                    </div>
                </div>
            </div>
        </AppLayout>
  )
}

export default DataAnalyze
