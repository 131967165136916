import React from 'react'
import Banner from '../assets/Home3.svg'
import BackButton from '../components/BackButton'
import AppLayout from '../layouts/AppLayout'
import { useTranslation, Trans } from 'react-i18next'
import Navbar from '../components/Navbar'

function Blackstar () {
  const { t } = useTranslation()

  return (
        <AppLayout title="BlackStar">
            <Navbar/>
            <div className="header-gradient bg-section rounded-br-medium md:rounded-br-large">
                <div className="container">
                    <div className="flex flex-col-reverse md:flex-row justify-between py-8 md:py-12">
                        <div className="flex flex-col">
                            <BackButton/>

                            <h3 className="mt-4 text-2xl md:text-4xl font-bold dark:text-true-primary text-true-secondary">{t('BlackStar')}</h3>

                            <p className="mt-4 text-2xl md:text-4xl md:leading-normal font-bold gradient-title">
                                <Trans i18nKey="blackstar_header_subtitle"/>
                            </p>

                            <p className="mt-9">{t('blackstar_header_paragraph_1')}</p>

                            <p className="mt-7 text-base">{t('blackstar_header_paragraph_2')}</p>

                            <p className="mt-7 text-base">{t('blackstar_header_paragraph_3')}</p>
                        </div>

                        <img className="md:-mr-20 ml-10 mb-8 max-w-md" src={Banner} alt=""/>
                    </div>
                </div>
            </div>
        </AppLayout>
  )
}

export default Blackstar
