import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function BackButton () {
  const history = useHistory()
  const { t } = useTranslation()
  return (
    <button className="gap-3 max-w-fit-content inline-flex flex-row justify-between items-center" onClick={history.goBack}>
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.6673 6.16658L3.52565 6.16658L8.18398 1.50825L7.00065 0.333252L0.333984 6.99992L7.00065 13.6666L8.17565 12.4916L3.52565 7.83325L13.6673 7.83325L13.6673 6.16658Z" fill="currentColor" />
      </svg>
      {t('back')}
    </button>
  )
}

export default BackButton
