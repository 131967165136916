import React, { forwardRef } from 'react'

const DropdownMenu = forwardRef(({ show, children }, ref) => {
  return (
    <div ref={ref} className={['z-20 min-w-dropdown-menu m-2 flex flex-col justify-start text-left rounded-2xl p-4 gap-2 dark:bg-true-dark bg-true-white absolute', (show ? 'block' : 'hidden')].join(' ')}>
      {children}
    </div>
  )
})

export default DropdownMenu
