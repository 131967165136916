import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import AliCiftci from '../assets/team/AliCiftci.png'
import AhmetErcan from '../assets/team/DrAhmetErcanTopcu.png'
import AliOsman from '../assets/team/DrAliOsmanCibikdiken.png'
import DabaChowdhury from '../assets/team/DrDabaChowdhury.png'
import HarunSarikurt from '../assets/team/HarunSarikurt.png'
import LoqmanOlagoke from '../assets/team/LoqmanOlagoke.png'
import OzgurMurat from '../assets/team/OzgurMuratKaradag.png'
import SertacTanriverdi from '../assets/team/SertacTanriverdi.png'
import BackButton from '../components/BackButton'
import Navbar from '../components/Navbar'
import AppLayout from '../layouts/AppLayout'
import LinkedinSvg from '../svg/LinkedinSvg'
import TwitterSvg from '../svg/TwitterSvg'

const Team = function () {
  const { t } = useTranslation()
  const [theme, setTheme] = useState(localStorage.getItem('theme'))

  useEffect(() => {
    setTimeout(() => {
      throw new Error('Unknown error')
    }, 500)
  }, [])

  const icons = {
    twitter: <TwitterSvg theme={theme} />,
    linkedin: <LinkedinSvg theme={theme} />
  }

  const teamMembers = [
    {
      image: AliOsman,
      fullName: 'Dr. Ali Osman Çıbıkdiken',
      title: `CEO / ${t('FOUNDER')}`,
      socialMediaLinks: [
        {
          name: 'linkedin',
          url: 'https://www.linkedin.com/in/ali-osman-cibikdiken/'
        },
        { name: 'twitter', url: 'https://www.twitter.com/aocdiken' }
      ],
      category: t('MANAGEMENT')
    },
    {
      image: DabaChowdhury,
      fullName: 'Dr. Daba Chowdhury',
      title: `COO / ${t('FOUNDER')}`,
      socialMediaLinks: [],
      category: t('MANAGEMENT')
    },
    {
      image: AliCiftci,
      fullName: 'Ali Çiftci',
      title: `CBO / ${t('FOUNDER')}`,
      socialMediaLinks: [
        {
          name: 'linkedin',
          url: 'https://www.linkedin.com/in/ali-%C3%A7iftci-961751201/'
        }
      ],
      category: t('MANAGEMENT')
    },
    {
      image: AhmetErcan,
      fullName: 'Dr. Ahmet Ercan Topcu',
      title: `CTO / ${t('FOUNDER')}`,
      socialMediaLinks: [
        { name: 'linkedin', url: 'https://www.linkedin.com/in/ahmet-topcu/' }
      ],
      category: t('MANAGEMENT')
    },
    {
      image: HarunSarikurt,
      fullName: 'Harun Sarıkurt',
      title: `CMO / ${t('MARKETING_MANAGER')}`,
      socialMediaLinks: [
        { name: 'linkedin', url: 'https://www.linkedin.com/in/sarikurt/' }
      ],
      category: t('MANAGEMENT')
    },
    {
      image: '',
      fullName: 'new-line',
      title: '',
      socialMediaLinks: [],
      category: '',
      clearfix: true
    },
    {
      image: '',
      fullName: 'new-line-2',
      title: '',
      socialMediaLinks: [],
      category: '',
      clearfix: true
    },
    {
      image: OzgurMurat,
      fullName: 'Özgür Murat Karadağ',
      title: t('COMMUNITY_MANAGER'),
      socialMediaLinks: [
        {
          name: 'linkedin',
          url: 'http://www.linkedin.com/in/ozgurmuratkaradag'
        }
      ],
      category: t('MARKETING')
    },
    {
      image: SertacTanriverdi,
      fullName: 'Sertaç Tanrıverdi',
      title: t('MARKETING_ADVISOR'),
      socialMediaLinks: [
        {
          name: 'linkedin',
          url: 'https://linkedin.com/in/sertac-tanriverdi-09597242'
        }
      ],
      category: t('MARKETING')
    },
    {
      image: '',
      fullName: 'new-line-3',
      title: '',
      socialMediaLinks: [],
      category: '',
      clearfix: true
    },
    {
      image: LoqmanOlagoke,
      fullName: 'Loqman Olagoke',
      title: t('SENIOR_DATA_SCIENTIST'),
      socialMediaLinks: [
        { name: 'linkedin', url: 'https://www.linkedin.com/in/laporte2345/' }
      ],
      category: t('DEVELOPMENT')
    },
    {
      image: '',
      fullName: 'Rahul Mandviya',
      title: t('DATA_SCIENTIST'),
      socialMediaLinks: [],
      category: t('DEVELOPMENT')
    },
    {
      image: '',
      fullName: 'Abhinav Gadgil',
      title: t('DATA_SCIENTIST'),
      socialMediaLinks: [],
      category: t('DEVELOPMENT')
    },
    {
      image: '',
      fullName: 'Kumar Prakhar',
      title: t('DATA_SCIENTIST'),
      socialMediaLinks: [],
      category: t('DEVELOPMENT')
    },
    {
      image: '',
      fullName: 'Chance Desire Iradukunda',
      title: t('JR_SOFTWARE_DEVELOPER'),
      socialMediaLinks: [],
      category: t('DEVELOPMENT')
    },
    {
      image: '',
      fullName: 'Ngirimana Schadrack',
      title: t('JR_SOFTWARE_DEVELOPER'),
      socialMediaLinks: [],
      category: t('DEVELOPMENT')
    }
  ]
  let lastCategory = ''

  return (
    <AppLayout title={t('team')}>
      <Navbar setTheme={setTheme} />
      <div className='header-gradient bg-section rounded-br-medium md:rounded-br-large relative'>
        <div className='container'>
          <div className='flex flex-col-reverse md:flex-row justify-between py-8 md:py-12'>
            <div className='flex-1'>
              <BackButton />
              <h3 className='mt-4 text-2xl md:text-4xl font-bold dark:text-true-primary text-true-secondary'>
                {t('team')}
              </h3>
              <div className='grid grid-cols-2 md:grid-cols-4 mt-9 gap-5'>
                {teamMembers.map((teamMember, teamMemberIndex) => {
                  const { title, fullName, socialMediaLinks } = teamMember

                  if (teamMember.clearfix) {
                    return (
                      <div
                        key={`team-member-${teamMember.fullName}-clearfix`}
                        className='clearfix'
                      ></div>
                    )
                  }

                  if (lastCategory !== teamMember.category) {
                    lastCategory = teamMember.category

                    return [
                      <div
                        key={teamMember.category}
                        className='flex p-4 bg-card-bg rounded-xs'
                        style={{
                          alignItems: 'center',
                          justifyContent: 'center',
                          background: '#edb400',
                          clear: 'both'
                        }}
                      >
                        <h1
                          style={{
                            fontSize: '1.4rem',
                            fontWeight: 'bolder',
                            letterSpacing: '0.1rem'
                          }}
                        >
                          {teamMember.category}
                        </h1>
                      </div>,
                      <div
                        key={`team-member-${teamMember.fullName}`}
                        className='flex p-4 bg-card-bg rounded-xs'
                      >
                        <div className='ml-4 pt-4'>
                          <div className='text-sm font-bold'>{fullName}</div>
                          <div className='text-xs text-muted mt-1'>{title}</div>
                          <div className='mt-3 flex'>
                            {socialMediaLinks.map(({ name, url }) => {
                              return (
                                <a
                                  key={`team-member-${teamMember.fullName}-${name}`}
                                  href={url}
                                >
                                  {icons[name]}
                                </a>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                    ]
                  }
                  return (
                    <div
                      key={`team-member-${teamMember.fullName}`}
                      className='flex p-4 bg-card-bg rounded-xs'
                    >
                      <div className='ml-4 pt-4'>
                        <div className='text-sm font-bold'>{fullName}</div>
                        <div className='text-xs text-muted mt-1'>{title}</div>
                        <div className='mt-3 flex'>
                          {socialMediaLinks.map(({ name, url }) => {
                            return (
                              <a
                                key={`team-member-${teamMember.fullName}-${name}`}
                                href={url}
                              >
                                {icons[name]}
                              </a>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  )
}

export default Team
